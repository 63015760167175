<datatable2 #datatable [definition]="tableDefinition" [data]="tableData" stickyheaders></datatable2>
<div class="footer">
  <div links>{{ datatable.filterRijen }} / {{ datatable.rijen }}</div>
  <div rechts>
    <div class="custom-control custom-switch d-inline-block">
      <input type="checkbox" class="custom-control-input" [id]="id('debugregels')" data-testid="debugswitch"  [(ngModel)]="debug"
             (ngModelChange)="reloaddata()">
      <label class="custom-control-label" [for]="id('debugregels')">Debug</label>
    </div>&nbsp;
    <button class="btn btn-primary btn-small" [class.animate]="animateNewdata" (animationend)="animateNewdata = false" (click)="planHaalData()"><span class="fas fa-sync"></span></button>
  </div>
</div>
