<comment>
  Eenvoudig scherm voor een comment bij een datum voor een personeelslid
</comment>
  <div class="head">
    Opmerking: {{naam}} - {{datum}}
  </div>
  <div class="content">
    <form #form='ngForm' autocomplete="off">
      <div class="form-row">
        <div class="w-col-12">
          <div class="form-group">
            <label [for]="id('comment')">Opmerking</label>
            <textarea [id]="id('comment')" class="form-control" name="comment" [(ngModel)]="record.comment"></textarea>
          </div>
        </div>
      </div>
          <label>Kies een kleur:</label>
      <div class="kleurkeuzes">
        <ng-container *ngFor="let kleur of [['#FFFFFF','#000000'],['#000000','#FFFFFF'],['#EE2E24','#FFFFFF'],['#047804','#FFFFFF'], ['#F29400','#FFFFFF'],['#0000FF','#E0E0E0']]">
            <div class="kleurkeuze" [class.selected]="record.achterkleur === kleur[0]" [style.background-color]="kleur[0]" [style.color]="kleur[1]" [style.border-color]="kleur[1]" (click)="setKleur(kleur)">Opmerking...</div>
        </ng-container>
      </div>
    </form>
  </div>
  <div class="foot">
    <div class="buttons" links>
      <button class="btn btn-secondary" (click)="closeWindow()">Annuleren</button>
      <button class="btn btn-danger" (click)="delete()" *ngIf="! readonly && ! isNieuw" [disabled]="! canDelete"
              [attr.title]="canDelete? 'Verwijder dit item' : 'Dit item kan niet verwijderd worden, omdat ernaar wordt verwezen.'">
        Verwijderen
      </button>
    </div>
    <div class="buttons" rechts>
      <button class="btn btn-primary" (click)="save()" [disabled]="form.invalid" *ngIf="(! (readonly || shouldReload))">
        Opslaan
      </button>
      <button class="btn btn-warning" (click)="reload()" *ngIf="shouldReload">Herladen (gewijzigd)</button>
    </div>
  </div>
