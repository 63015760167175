<comment>
  Planitemedit: edits a planitem
</comment>
<div class="head">
  {{ record.project?.naam }}
</div>
<div class="content">
  <form #form='ngForm' autocomplete="off">
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" [id]="id('gegevenstab')" data-toggle="tab" role="tab"
           [attr.aria-controls]="id('gegevens')" aria-selected="true" [href]="hashid('gegevens')">Dienst</a>
      </li>
      <li class="nav-item" *ngIf="magPersoneel && record.mainFunction">
        <a class="nav-link" [id]="id('personeeltab')" data-toggle="tab" role="tab"
           [attr.aria-controls]="id('personeel')"
           aria-selected="true" [href]="hashid('personeel')">Invulling</a>
      </li>
      <li class="nav-item" *ngIf="magLog">
        <a class="nav-link" [id]="id('logtab')" data-toggle="tab" role="tab" [attr.aria-controls]="id('log')"
           aria-selected="true" [href]="hashid('log')">Log</a>
      </li>
    </ul>
    <div class="tab-content mt-2">
      <div class="tab-pane fade show active" [id]="id('gegevens')" role="tabpanel"
           [attr.aria-labelledby]="id('gegevenstab')">
        <div class="form-row">
          <div class="w-col-12">
            <div class="form-group">
              <label [for]="id('project')">Project</label>
              <ng-select [id]="id('project')" [(ngModel)]="record.project_id" class="form-control" name="project"
                         [disabled]="readonly || isLeverancier"
                         (change)="projectChange()">
                <ng-container *ngFor="let project of projects">
                  <ng-option [value]="project.id">{{ project.label }}
                    <ng-container *ngIf="lookupHashes['werkmaatschappij']">
                      - {{ werkmaatschappij(project.werkmaatschappij_id) }}
                    </ng-container>
                  </ng-option>
                </ng-container>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="w-col-4">
            <div class="form-group">
              <label [for]="id('startdate')">Datum</label>
              <sihwdatepicker class="form-control" [id]="id('startdate')" required name="startdate"
                              placeholder="dd-mm-jjjj"
                              [(ngModel)]="record.startdate" [bsConfig]="datepickerconfig"
                              [minDate]="mindate" [maxDate]="record.project?.einddatum"
                              (ngModelChange)="momentChange()"
                              [disabled]="readonly || isLeverancier"></sihwdatepicker>
            </div>
          </div>
          <div class="w-col-4">
            <div class="form-group">
              <label [for]="id('starttime')">Begin</label>
              <sihwtijdveld class="form-control" [id]="id('starttime')" required name="starttime" placeholder="uu:mm"
                            [(ngModel)]="record.starttime" [voorloopnul]="true"
                            (ngModelChange)="momentChange()"
                            [disabled]="readonly || isLeverancier"></sihwtijdveld>
            </div>
          </div>
          <div class="w-col-4">
            <div class="form-group">
              <label [for]="id('endtime')">Eind</label>
              <sihwtijdveld class="form-control" [id]="id('endtime')" required name="endtime" placeholder="uu:mm"
                            [(ngModel)]="record.endtime" [voorloopnul]="true"
                            (ngModelChange)="momentChange()"
                            [disabled]="readonly || isLeverancier"></sihwtijdveld>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="w-col-3 w-col-md-2">
            <div class="form-group">
              <label [for]="id('capacity')">Capaciteit</label>
              <input class="form-control" type="number" min="1" step="1" [id]="id('capacity')" name="capacity"
                     [disabled]="readonly || isLeverancier"
                     [(ngModel)]="record.capacity" required (change)="fixPersoneelrows()"
                     (ngModelChange)="roundCapacity()">
            </div>
          </div>
          <div class="w-col-6 w-col-md-7">
            <div class="form-group">
              <label [for]="id('mainfunction')">Functie</label>
              <ng-select class="form-control" [id]="id('mainfunction')" bindValue="id" name="mainfunction"
                         [disabled]="readonly || isLeverancier"
                         [(ngModel)]="record.mainFunction" (change)="functionsChanged()">
                <ng-container *ngFor="let func of relevantFunctions">
                  <ng-option [value]="func.id">{{ func.naam }}</ng-option>
                </ng-container>
              </ng-select>
            </div>
          </div>
          <div class="w-col-3">
            <div class="form-group">
              <label [for]="id('aanvraagnummer')">Aanvraagnummer <span class="inforondje"
                                                                       title="Extern aanvraagnummer"></span></label>
              <input class="form-control" type="text" maxlength="20" [id]="id('aanvraagnummer')" name="aanvraagnummer"
                     [(ngModel)]="record.aanvraagnummer" [disabled]="readonly || isLeverancier">
            </div>
          </div>
        </div>
        <div class="form-group">
          <label [for]="id('functionalias')">Rijalias <span class="inforondje"
                                                            title="Maak een aparte rij op het planbord met deze extra omschrijving"></span></label>
          <input class="form-control" type="text" maxlength="100" [id]="id('functionalias')" name="functionalias"
                 [(ngModel)]="record.functionalias" [disabled]="readonly || isLeverancier">
        </div>
        <div class="form-group" *ngIf="! isLeverancier">
          <label [for]="id('leverancier')">In te vullen door leverancier:</label>
          <ng-select [id]="id('leverancier')" name="leverancier" class="form-control"
                     [(ngModel)]="record.leverancier_id" bindValue="id">
            <ng-option *ngFor="let leverancier of leveranciersInWM()" [value]="leverancier.id">{{ leverancier.naam }}
            </ng-option>
          </ng-select>
        </div>

        <div class="form-group">
          <label [for]="id('opmerkingen')">Opmerkingen voor uitvoerders</label>
          <textarea class="form-control" [id]="id('opmerkingen')" [disabled]="readonly || isLeverancier"
                    name="opmerkingen"
                    [(ngModel)]="record.opmerkingen"></textarea>
        </div>

        <div *ngIf="lookups.functions">
          <div><label>Extra functies</label></div>
          <ng-container *ngFor="let func of relevantFunctions">
            <div class="select-pill" [class.select]="record.hasExtrafunction(func)"
                 *ngIf="func.id !== record.mainFunction"
                 (click)="toggleExtrafunction(func)">{{ func.naam }}
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="(! readonly) && (! isLeverancier) && (! record.serieskey)">
          <div class="form-row mt-3 mb-2">
            <div class="w-col-12">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" [id]="id('planSeries')" name="planSeries"
                       [(ngModel)]="planSeries.create">
                <label class="form-check-label" [for]="id('planSeries')">
                  Maak een serie
                </label>
              </div>
            </div>
          </div>
          <ng-container *ngIf="planSeries.create">
            <div class="form-row">
              <div class="w-col-4 w-col-sm-3 w-col-lg-2">
                <div class="form-group">
                  <label [for]="id('planseries_enddatum')">T/M einddatum</label>
                  <sihwdatepicker class="form-control" [id]="id('planseries_enddatum')" required
                                  name="planseries_enddatum"
                                  placeholder="dd-mm-jjjj"
                                  [(ngModel)]="planSeries.endDate" [bsConfig]="datepickerconfig"
                                  [minDate]="record.startdate" [maxDate]="record.project?.einddatum"></sihwdatepicker>
                </div>
              </div>
              <div class="w-col-8 w-col-md-6 w-col-lg-5">
                <div class="div">
                  <label>Weekdagen (start)</label>
                </div>
                <div class="mt-1">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" [id]="id('weekdays_mon')"
                           [(ngModel)]="planSeries.weekdays.mon" name="weekdays_mon">
                    <label class="form-check-label" [for]="id('weekdays_mon')">Ma</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" [id]="id('weekdays_tue')"
                           [(ngModel)]="planSeries.weekdays.tue" name="weekdays_tue">
                    <label class="form-check-label" [for]="id('weekdays_tue')">Di</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" [id]="id('weekdays_wed')"
                           [(ngModel)]="planSeries.weekdays.wed" name="weekdays_wed">
                    <label class="form-check-label" [for]="id('weekdays_wed')">Wo</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" [id]="id('weekdays_thu')"
                           [(ngModel)]="planSeries.weekdays.thu" name="weekdays_thu">
                    <label class="form-check-label" [for]="id('weekdays_thu')">Do</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" [id]="id('weekdays_fri')"
                           [(ngModel)]="planSeries.weekdays.fri" name="weekdays_fri">
                    <label class="form-check-label" [for]="id('weekdays_fri')">Vrij</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" [id]="id('weekdays_sat')"
                           [(ngModel)]="planSeries.weekdays.sat" name="weekdays_sat">
                    <label class="form-check-label" [for]="id('weekdays_sat')">Za</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" [id]="id('weekdays_sun')"
                           [(ngModel)]="planSeries.weekdays.sun" name="weekdays_sun">
                    <label class="form-check-label" [for]="id('weekdays_sun')">Zo</label>
                  </div>
                </div>
              </div>
              <div class="w-col-4 w-col-sm-3 w-col-md-2">
                <div class="form-group">
                  <label [for]="id('everyNumWeeks')">Om de X week</label>
                  <input type="number" class="form-control" min="1" max="52" step="1" required name="everyNumWeeks"
                         [id]="id('everyNumWeeks')"
                         [(ngModel)]="planSeries.everyNumWeeks" (ngModelChange)="roundNumWeeks()">
                </div>
              </div>
            </div>
            <p class="small">{{ planSeriesText() }}</p>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="(! readonly) && (! isLeverancier) && record?.seriesItems?.length">
          <hr>
          <h4>Serie</h4>
          <p>Dit item maakt deel uit van een serie van {{ record.seriesItems.length }} items
            tussen {{ seriesdate('first') }}
            en {{ seriesdate('last') }}. De items zijn los van elkaar te bewerken. Je kan ook de hele reeks in één keer
            verwijderen. Dit item blijft dan over. Ook kan je juist dit item losmaken van de rest van de serie.</p>
          <div class="d-flex justify-content-between">
            <button class="btn btn-primary" [disabled]="readonly" (click)="detachFromSeries()"
                    title="Koppel dit item los van de serie, zodat hij niet verwijderd wordt als je de serie verwijdert. Na het opslaan is het item losgekoppeld.">
              Loskoppelen
            </button>
            <button class="btn btn-danger" [disabled]="readonly || (! canDeleteSeries())" (click)="deleteSeries()"
                    title="Verwijder alle andere items uit de serie. Dit item blijft.">Verwijder
              overige
            </button>
          </div>
        </ng-container>
      </div>
      <div class="tab-pane fade" *ngIf="magPersoneel && record.mainFunction" [id]="id('personeel')" role="tabpanel"
           [attr.aria-labelledby]="id('personeeltab')">
          <ng-container *ngFor="let kritiek of record.kritiekMetCat">
            <ng-container *ngIf="kritiek[0] !== 'capacity'">
              <div class="kritiek">{{ kritiek[1] }}</div>
            </ng-container>
          </ng-container>

        <ng-container *ngFor="let ppi of record.personeel_planitem;let num = index;">
          <div class="personeelselectcontainer" [class.firsttoomuch]=" num === (record.capacity || 0) ">
            <comment>als dit wordt ingevuld door een leverancier en de gekozen persoon is niet extern, dan beelden we
              een mededeling af
            </comment>
            <ng-container
              *ngIf="isLeverancier && ppi.personeelvast_id && personeel(ppi.personeelvast_id)?.groep !== 'Extern'">
              <input type="text" disabled class="form-control" [value]="personeel(ppi.personeelvast_id)?.naam">
            </ng-container>
            <ng-container
              *ngIf="(! isLeverancier) || (! ppi.personeelvast_id) || personeel(ppi.personeelvast_id)?.groep === 'Extern'">
              <ng-select class="form-control" bindValue="id" [name]="'ppi_'+ num"
                         [disabled]="readonly || (! magPersoneel)"
                         [(ngModel)]="ppi.personeelvast_id" (ngModelChange)="personeelChange(ppi)">
                <ng-container *ngFor="let pdata of fittedPersoneel; let pnum = index">
                  <ng-container
                    *ngIf="isLeverancier && pnum > 0 && fittedPersoneel[pnum-1].leverancierfit !== pdata.leverancierfit">
                    <ng-option [disabled]="true">-----</ng-option>
                  </ng-container>
                  <ng-option [value]="pdata.p.id" *ngIf="noDuplicatePersoneel(pdata.p.id,ppi)">
                    <div
                      class="personeelrow"
                      [attr.functionfit]="pdata.functionfit"
                      [title]="functionfit[pdata.functionfit] ?? ''">{{ pdata.p.naam }}
                      <ng-container *ngIf="pdata.p.telefoonnummer?.length">({{ pdata.p.telefoonnummer }}
                        )
                      </ng-container>
                    </div>
                  </ng-option>
                </ng-container>
              </ng-select>
            </ng-container>
          </div>
          <ng-container
            *ngIf="ppi.personeelvast_id && personeel(ppi.personeelvast_id)?.groep !== 'Personeel' && (! isLeverancier)">
            <div class="ml-5">
              <div class="input-group">
                <select [name]="'leverancier_' + num" class="form-control" required
                        [disabled]="readonly || (! magPersoneel)"
                        [(ngModel)]="ppi.leverancier_id">
                  <ng-container *ngFor="let leverancier of lookups['leverancier']">
                    <option [ngValue]="leverancier.id"
                            *ngIf="(leverancier.leverancierstype === 'Inhuur' || leverancier.id === personeel(ppi.personeelvast_id)?.leverancier_id) && leverancier.werkmaatschappij_id === record.project.werkmaatschappij_id">{{ leverancier.naam }} {{ leverancier.id === personeel(ppi.personeelvast_id)?.leverancier_id ? '(standaard leverancier)' : (leverancier.id === ppi.leverancier_id ? '(alternatieve leverancier)' : '') }}
                      <ng-container *ngIf="!leverancier.exact_id">NIET BOEKBAAR</ng-container>
                    </option>
                  </ng-container>
                </select>
                <div class="input-group-append">
                  <button class="btn btn-primary" type="button" title="Zet standaard"
                          [disabled]="readonly || (! magPersoneel) || ppi.leverancier_id === personeel(ppi.personeelvast_id)?.leverancier_id"
                          (click)="ppi.leverancier_id = personeel(ppi.personeelvast_id)?.leverancier_id"><span
                    class="fas fa-undo"></span>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <comment>Ruimte voor de select</comment>
        <div style="height: 10em"></div>
      </div>
      <div class="tab-pane fade" *ngIf="magLog" [id]="id('log')" role="tabpanel" [attr.aria-labelledby]="id('logtab')">
        <ng-container *ngFor="let log of record.planitemlog">
          <div class="logregel">
            <div class="logheader">
              <div class="logactie">{{ log.actie }}</div>
              <div class="logdatum">{{ log.createdAt | mdate:"D-M-YYYY HH:mm" }}</div>
              <div class="loggebruiker">{{ log.gebruiker?.naam || log.gebruiker?.gebruikersnaam || '?' }}</div>
            </div>
            <div class="logdetail">{{ log.wijzigingen_tekst }}</div>
          </div>
        </ng-container>
      </div>
    </div>
  </form>
</div>
<div class="foot">
  <div class="buttons" links>
    <button class="btn btn-secondary" (click)="closeWindow()">Annuleren</button>
    <button class="btn btn-danger" (click)="delete()" *ngIf="! readonly && ! isNieuw" [disabled]="! canDelete"
            [attr.title]="canDelete? 'Verwijder dit item' : 'Dit item kan niet verwijderd worden, omdat ernaar wordt verwezen.'">
      Verwijderen
    </button>
  </div>
  <div class="buttons" rechts>
    <comment>ng-select does not work well with required</comment>
    <button class="btn btn-primary" (click)="save()" *ngIf="(! (readonly || shouldReload))"
            [disabled]="! canSave()">
      Opslaan
    </button>
    <button class="btn btn-warning" (click)="reload()" *ngIf="shouldReload">Herladen (gewijzigd)</button>
  </div>
</div>

