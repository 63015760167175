/*
* leveranciers.ts
* 9-3-2023 - Jelmer Jellema - Spin in het Web B.V.
*/

import {Component, Injector, Input} from '@angular/core';
import {Leverancier} from '../../models/leverancier';
import {hash} from '../../app/types';
import {Werkmaatschappij} from '../../models/werkmaatschappij';
import {Datatable2scherm} from '../abstracts/datatable2scherm.class';
import {DatatableDef} from 'sihw-ng/datatable2/datatable2.component';

@Component({
  selector: 'leverancierlijst',
  templateUrl: './leverancierlijst.html'
})
export class Leverancierlijstscherm extends Datatable2scherm {
  @Input() filterBeoordelen = false; //moet er geopend worden met filteren op beoordelen?
  _starttitle = 'Leveranciers';
  icon = 'fas fa-truck';
  model = Leverancier;

  werkmaatschappijen: hash<Werkmaatschappij> = {};

  tableQuery = Leverancier.recordquery([], []);

  editscherm = "Leverancierportaal";

  //constructor ivm injection
  constructor(inject: Injector) {
    super(inject, 'debug');
  }

  get title(): string {
    if (this.filterBeoordelen)
    {
      return "Beoordelen leveranciers";
    }
    return super.title;
  }

  ngOnInit() {
    if (this.api.can('leverancier', 'zelf') && (!this.api.can('leverancier', 'full')))
    {
      //geen lijst afbeelden, maar het portaal
      //even helemaal buiten de init
      setTimeout(() => {
        void this.createWindow("Leverancierportaal"); //openen
        this.asyncCloseWindow(); //en deze weg
      });
      return;
    }
    //moeten we filteren op beoordelen?
    if (this.filterBeoordelen)
    {
      this.tableDefinition.velden.beoordelen.standaardfilter = 1;
    }
    else if (window.location.host.match(/127.0.0.1|localhost/)) {
      this.log.warn('Tijdelijk: openen testleverancier');
      this.edit(541);
    }
    super.ngOnInit();
  }

  async dataGetter(): Promise<Leverancier[]> {
    this.werkmaatschappijen = await Werkmaatschappij.getLookup();
    return <Leverancier[]> await super.dataGetter();
  }

  /**
   * Check of de leverancier een exact-id heeft, en zoja: open het portaal op deze
   * @param leverancier
   */
  openPortaal(leverancier: Leverancier) {
    if (! leverancier.exact_id)
    {
      this.api.toast("Deze leverancier heeft geen koppeling met exact.");
      return;
    }
    super.edit(leverancier.id);
  }

  public readonly tableDefinition: DatatableDef = {
    standaardsortering: {
      key: 'naam',
      richting: 1
    },
    velden: {
      naam: {
        label: 'Naam'
      },
      leverancierstype: {
        label: 'Type',
        type: 'enum'
      },
      werkmaatschappij_id: {
        label: 'Werkmaatschappij', //gewijzigd  was eerst mvp_do, maar er zijn nu meer mogelijkheden
        type: 'enum',
        format: wmid => this.werkmaatschappijen[wmid]?.afkorting
      },
      beoordelen: {
        label: 'Beoordelen?',
        type: 'bool',
      },
      goedkeurstatus: {
        label: 'Status',
        type: 'enum',
        format: goedkeurstatus => {
          switch (goedkeurstatus) {
            case 'goedgekeurd':
              return 'Ok';
            case 'afgekeurd':
              return 'Afgekeurd';
            case 'forceer':
              return 'Forceer portaal';
            default:
              return goedkeurstatus;
          }
        }
      }
    }
  };
}
