/*
* exactlog.ts
* 26-03-2024 - Jelmer Jellema - Spin in het Web B.V. Apeldoorn NL
*/

import {Basemodel, ModelField, ModelRelation} from './basemodel';
import {Werkmaatschappij} from './werkmaatschappij';
import {Gebruiker} from './gebruiker';

export class Exactlog extends Basemodel {
  static override table = 'exactlog';
  static override defaultOrder = [];

  @ModelField() override id: number;
  @ModelField() werkmaatschappij_id: number;
  @ModelField() gebruiker_id: number;
  @ModelField() onderwerp: string;
  @ModelField() niveau: 'debug' | 'info' | 'waarschuwing' | 'fout';
  @ModelField() melding: string;
  @ModelField() request: string;
  //data zetten we er gewoon niet in
  @ModelField() createdAt: string;

  @ModelRelation({
    model: Werkmaatschappij,
    type: '1:1'
  }) werkmaatschappij: Werkmaatschappij;
  @ModelRelation({
    model: Gebruiker,
    type: '1:1'
  }) gebruiker: Gebruiker;
}
