<div class="head">
  BAM Import
</div>
<div class="content">
  <p>Met deze tool importeer je diensten uit een Excel-bestand dat door BAM is aangeleverd. Wij controleren het document
    op vorm. Het is belangrijk dat je zelf door de inhoud bent gelopen.</p>


  <form #form='ngForm' autocomplete="off">
    <h4 class="mb-3">Bestand</h4>
    <div class="form-row">
      <div class="w-col-12 mb-3">
        <div class="custom-file">
          <input [id]="id('excel')" type="file" class="custom-file-input"
                 accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                 required
                 name="excelfile"
                 (change)="fileGekozen($event)">
          <label class="custom-file-label"
                 [for]="id('excel')">{{ excelfile ? excelfile.name : 'Kies een Excel-bestand' }}</label>
        </div>
      </div>
      <div class="w-col-12 mb-3">
        <p data-testid="statustekst"><em>{{status}}</em></p>
      </div>
    </div>
    <h4>Bestemming</h4>
    <div class="form-row">
      <ng-container *ngIf="werkmaatschappijen.length > 1">
        <div class="w-col-6 mb-3">
          <div class="form-group">
            <label [for]="id('wm')">Werkmaatschappij</label>
            <select class="form-control" [id]="id('wm')" name="wm" [(ngModel)]="werkmaatschappij">
              <ng-container *ngFor="let wm of werkmaatschappijen">
                <option [ngValue]="wm">{{ wm.afkorting }}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </ng-container>
      <div class="w-col-6 mb-3">
        <div class="form-group">
          <label [for]="id('project')">Project</label>
          <select class="form-control" [id]="id('project')" name="project" required [(ngModel)]="project">
            <ng-container *ngFor="let pr of werkmaatschappij?._tags.projecten; trackBy: trackByThis">
              <option [ngValue]="pr">{{ pr.naam }} - {{pr.omschrijving}} - {{ pr.klantnaam }}</option>
            </ng-container>
          </select>
        </div>
      </div>
    </div>
  </form>
  <h4>Preview</h4>
  <ng-container *ngIf="! diensten?.length"><p><em>Geen diensten</em></p></ng-container>
  <ng-container *ngIf="diensten?.length">
    <table class="table table-hover table-striped">
      <thead>
      <tr>
        <th>Datum</th>
        <th>Begin</th>
        <th>Eind</th>
        <th>Functie</th>
        <th>Alias</th>
        <th>Aanvraagnummer</th>
        <th>Opmerkingen</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let dienst of diensten">
        <tr>
          <td>{{dienst.startdate | mdate:"DD-MM-YYYY"}}</td>
          <td>{{dienst.starttime}}</td>
          <td>{{dienst.endtime}}</td>
          <td>{{dienst.functie}}</td>
          <td>{{dienst.alias}}</td>
          <td>{{dienst.aanvraagnummer}}</td>
          <td>{{dienst.opmerkingen}}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </ng-container>
</div>
<div class="foot">
  <div class="buttons" links>
    <button class="btn btn-secondary" (click)="closeWindow()">Annuleren</button>
  </div>
  <div class="buttons" rechts>
    <button class="btn btn-primary" [disabled]="! isValid()" (click)="doeImport()">Importeer</button>
  </div>
</div>
