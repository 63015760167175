/*
* dragtoscroll.module.ts
* 29-11-2022 - Jelmer Jellema - Spin in het Web B.V.
*
* <div class="mycontainer" sihw-drag-to-scroll">....</div>
*
* .mycontainer {
*   height: 100%;
*   width: 100%;
*   overflow: hidden; //or scroll
*
*   &.sihw-dragging {
*      //this class is set when scrolling by dragging
*      cursor: grab;
* }
*
* The container can now be scrolled by grabbing. Make sure the content uses the full needed width or height, (height: fit-content)
*/

import {NgModule} from '@angular/core';
import {SihwlogModule} from '../sihwlog/sihwlog.module';
import {SihwDragToScroll} from './dragtoscroll';

@NgModule({
  declarations: [
    SihwDragToScroll
    ],
  imports: [
    SihwlogModule
  ],
  exports: [
    SihwDragToScroll
  ]
}) export class SihwDragToScrollModule {}
