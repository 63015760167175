/*
* planitem_function.ts
* 2-11-2022 - Jelmer Jellema - Spin in het Web B.V.
*
* Model for mapping of functions to planitems
*/

import {Basemodel, ModelField} from './basemodel';

export class PlanitemFunction extends Basemodel {
  static override table = 'planitem_function';

  //planitem is owner
  @ModelField() id: number;
  @ModelField() functie_id: number;
  @ModelField() main: boolean;

}
