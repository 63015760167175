/*
* werkbonedit.ts
* 9-9-2024 - Jelmer Jellema - Spin in het Web B.V.
* Paar aanpasbare dingen op de werkbon
* Dit is geen gewone editmodelscherm, want daarvoor husselen we de data te veel
* we krijgen een ppi binnen, met planitem en project gekoppeld, en halen de werkbondata even opnieuw op voor volledigheid
*
* we doen weinig qua beveiliging, we zijn echt een helper van werkbonnenlijst
*/

import {Component, HostBinding, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {Appscherm} from '../abstracts/appscherm';
import {PersoneelPlanitem} from '../../models/personeel_planitem';
import {Werkbon} from '../../models/werkbon';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'werkbon-edit',
  templateUrl: './werkbonedit.html',
  styleUrls: ['./werkbonedit.scss'],
})
export class Werkbonedit extends Appscherm implements OnInit {
  @HostBinding('class.editscherm') readonly _hostclasseditscherm = true; //wel de class van een editscherm
  @Input() ppi: PersoneelPlanitem; //wordt gezet door werkbonnenliijst
  @ViewChild('form', {static: false}) form: NgForm;

  werkbon: Werkbon; //zetten we los van de ppi
  readonly: boolean = true; //zijn we readonly?
  _starttitle = 'Werkbon';
  icon = 'fas fa-file-signature';
  closeButton = false; //we willen geen closebutton, want pdf-knop doet een save, en dan moeten we het resultaat bewaren
  gewijzigd: boolean = false; //is de data gewijzigs? (resultaat bij closewindow)

  //constructor ivm injection
  constructor(inject: Injector) {
    super(inject, 'debug');
  }

  get title(): string {
    return `Werkbon van ${this.ppi?.personeel?.naam || '?'}`;
  }

  /**
   * Haal de werkbon even op op basis van de binnengekomen ppi
   */
  async ngOnInit() {
    if (!this.ppi?.werkbon) {
      this.log.warn('werkbonedit zonder ppi/werkbon');
      this.closeWindow(false);
      return;
    }
    //ophalen alles van de werkbon
    try {
      this.werkbon = await Werkbon.fromId(this.ppi.werkbon.id, [], [], this.ppi);
    } catch {
      //er is alwel een toast geweest
      this.closeWindow(false);
      return;
    }
    //readonly
    this.readonly = (this.ppi.ingediend || this.api.can('werkbon', 'readonly'));
  }

  async save() {
    try {
      if (this.form.form.dirty) {
        this.gewijzigd = true; //resultaat zo
        await this.werkbon.save(); //wacht, want anders gaat het resultaat terug voordat de data gewijzigd is
      }
      this.closeWindow(this.gewijzigd); //klaar
    } catch {
    }
    //anders is er wel een toast geweest
  }

  /**
   * Snelle werkbon-pdf
   */
  async pdf() {
    if (! this.readonly)
    {
      //even saven
      try {
        if (this.form.form.dirty) {
          await this.werkbon.save();
          this.gewijzigd = true; //als we straks sluiten, dan is er een wijziging geweest
        }
        this.form?.form?.markAsPristine();
      }
      catch {}
    }
    //maak de pdf
    await this.api.maakWerkbonPDF([this.ppi.id]);
  }
}
