/*
Simpele sortBy pipe. Kan een array van primitieven sorteren en een array
van hashes op een bepaalde kolom. En dan asc of desc
input/list | sortBy:’asc|desc’: ‘property’
 */

import {NgModule} from '@angular/core';
import {SortByPipe} from './sortbypipe';

@NgModule({
  declarations: [SortByPipe],
  exports: [SortByPipe]
}) export class SortbypipeModule {}
