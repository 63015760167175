/*
* groep.ts
* 7-5-2023 - Jelmer Jellema - Spin in het Web B.V.
*/

import {Basemodel, ModelField} from './basemodel';

export class Groep extends Basemodel {
  static table = 'groep';
  static defaultOrder = [['groep','ASC']]

  @ModelField() id: number;
  @ModelField() groep: string;
  @ModelField() acl: string;
  @ModelField() beschrijving: string;
}
