/*
* openfocusdirective.ts
* 2-12-2020 - Jelmer Jellema - Spin in het Web B.V.
*/

import {AfterViewInit, Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[autofocus],[sihwAutofocus]'
})
export class Autofocusdirective implements AfterViewInit {
  constructor(private el: ElementRef) {
  }

  ngAfterViewInit() {
    setTimeout((_: any) => this.el.nativeElement.focus());
  }
}
