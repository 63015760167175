/*
* planitemedit.ts
* 10-11-2022 - Jelmer Jellema - Spin in het Web B.V.
*
* Editor for a planblock on the planboard
*/

import {Component, Injector, Input} from '@angular/core';
import {Planitem} from '../../models/planitem';
import {Editmodelscherm} from '../abstracts/editmodelscherm';
import {Project} from '../../models/project';
import {Functie} from '../../models/functie';
import {PlanitemFunction} from '../../models/planitem_function';
import * as moment from 'moment';
import {hash} from '../../app/types';
import {Personeel} from '../../models/personeel';
import {PersoneelPlanitem} from '../../models/personeel_planitem';
import {Datarecord} from '../../services/api/api.service';
import {PersoneelFunctie} from '../../models/personeel_functie';
import {cloneDeep} from 'lodash-es';
import {Werkmaatschappij} from '../../models/werkmaatschappij';
import {Leverancier} from '../../models/leverancier';
import {PersoneelAfwezigheid} from '../../models/personeel_afwezigheid';
import {Basemodel} from '../../models/basemodel';
import {KeyedTimeout} from 'sihw-ng/keyedtimeout/keyedtimeout';

const translated_days = {
  mon: 'maandag',
  tue: 'dinsdag',
  wed: 'woensdag',
  thu: 'donderdag',
  fri: 'vrijdag',
  sat: 'zaterdag',
  sun: 'zondag'
};

const daykeys = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

@Component({
  templateUrl: './planitemedit.html',
  styleUrls: ['./planitemedit.scss']
})

export class Planitemedit extends Editmodelscherm<typeof Planitem> {
  _starttitle = 'Planning';
  icon = 'fas fa-business-time';
  model = Planitem;

  relevantFunctions: Functie[] = [];
  irrelevantFunctions: Functie[] = [];
  mindate: string = null;
  laatste_werkmaatschappij: number = 0; //om te onthouden welke werkmaatschappij er is. Omdat we alle ppi's weggooien als we switchen
  magPersoneel: boolean = false; //mag deze user  ook personeel editen?
  magLog: boolean = false; //en log bekijken?
  public isLeverancier = false; //speciale leveranciertoestemming?

  @Input() projects: Project[]; //the projectlist from the planbord
  @Input() allitemsPerdate: hash<Planitem[]> = {}; //nodig voor overlapcheck
  lookupQueries = {
    functions: {
      model: Functie,
      aclModel: Project
    },
    personeel: {
      model: Personeel,
      submodels: [PersoneelFunctie, PersoneelAfwezigheid],
      aclModel: Project,
      suboptions: <[typeof Basemodel, hash<any>][]> [
        [PersoneelAfwezigheid,
          {
            //hier halen we het ruim op: alles vanaf een half jaar geleden, en in de toekomst
            //zodat het goed cachet
            where: {
              $or: {
                startdatum: {
                  $gt: moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD')
                },
                einddatum: {
                  $gt: moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD')
                }
              }
            },
            required: false
          }
        ]
      ]
    },
    werkmaatschappij: {
      model: Werkmaatschappij,
      aclModel: Planitem
    },
    leverancier: {
      model: Leverancier,
      aclModel: Planitem
    }
  };
  planSeries = {
    create: <boolean> false,
    everyNumWeeks: <number> 1,
    weekdays: {
      mon: <boolean> false,
      tue: <boolean> false,
      wed: <boolean> false,
      thu: <boolean> false,
      fri: <boolean> false,
      sat: <boolean> false,
      sun: <boolean> false,
    },
    endDate: <string> ''
  };
  fittedPersoneel: hash<any>[] = []; //data for the personeelslist, that uses onPush updates, see fitPersoneel
  readonly functionfit = {
    4: 'Voldoet aan alle functie-eisen, hoofdfuncties passen',
    3: 'Voldoet aan alle functie-eisen',
    2: 'Hoofdfunctie past, maar niet alle extra functies passen',
    1: 'Mist de juiste functies',
    0: 'Afwezig'
  };
  //eem debouncer voor ux-wijzigingen
  private debouncer: KeyedTimeout = new KeyedTimeout();

  constructor(inject: Injector) {
    super(inject, 'debug');
    this.isLeverancier = this.api.can('planitem', 'leverancier'); //speciale permissie
    this.magPersoneel = this.isLeverancier || this.api.can('personeelvast_planitem', 'full'); //leverancier krijgt sowieso alleen items te zien die die mag editen
    this.magLog = true;
    //leverancier mag minder
    if (this.isLeverancier) {
      delete this.lookupQueries.werkmaatschappij;
      delete this.lookupQueries.leverancier;
      this.magLog = false;
    }
  }

  override get recordBeschrijving(): string {
    return 'dit planitem';
  }

  ////////////////////////////////////////////////////////////////
  override get title(): string {
    let t: string;
    if (this.record?.project) {
      t = `Plan ${this.record.project.naam}`;
    } else {
      t = `Plan`;
    }
    if (this.record.startdate) {
      t += ` ${moment(this.record.startdate).format('D-M-YY')}`;
    }
    return t;
  }

  /**
   * Overschrijf de canwrite van de parent, want leveranciers kunnen ook schrijven, zij het beperkt
   */
  override async canWrite(): Promise<boolean> {
    return this.isLeverancier || super.canWrite();
  }

  async afterHaaldata(): Promise<void> {
    await super.afterHaaldata();

    //fix the owner if needed
    this.fixOwner(); //and calculates mindate

    //make sure we have the right number of personeel rows
    this.fixPersoneelrows();

    this.fitPersoneel(); //fit and sort the personeel

    //dingen die we niet doen bij leveranciers, omdat we niet bij die data kunnen komen
    if (!this.isLeverancier) {
      this.checkPersoneelskritiek(); //fix overlapcheck

      //series?
      await this.record.checkSeries();
    }

    this.laatste_werkmaatschappij = this.record.project?.werkmaatschappij_id;

  }

  /**
   * Fix de overlap- en afwezigheidskritiek check bij wijzigen van item (muv series) en na clonen van het record
   */
  checkPersoneelskritiek(): void {
    this.record.resetKritiek(); //we beginnen opnieuw
    if (this.record.startdate?.length && this.record.starttime?.length) {
      let checkdatum = moment(this.record.startdatetime).subtract(1, 'day');
      let checkitems = [];
      for (let i = 0; i < 3; i++) {
        let checkdatumstr = checkdatum.format('YYYY-MM-DD');
        this.log.debug(checkdatumstr, this.allitemsPerdate[checkdatumstr]);
        if (this.allitemsPerdate[checkdatumstr]) {
          checkitems.push(...this.allitemsPerdate[checkdatumstr]);
        }
        checkdatum.add(1, 'day');
      }
      //checkitems zijn nu de relevante items voor overlapcheck
      this.log.debug(`Checkpersoneeloverlap`, checkitems);
      this.record.checkPersoneeloverlap(checkitems);
      this.log.debug(this.record.kritiekTekst);
    }
  }

  /**
   * on project change
   */
  projectChange() {
    this.fixOwner(); //set owner through project_id
    //is dit een wisseling in werkmaatschappij?
    if (this.record.project?.werkmaatschappij_id !== this.laatste_werkmaatschappij) {
      this.log.debug(`WIJZIGING WERKMAATSCHAPPIJ`);
      this.record.personeel_planitem = [];
      this.laatste_werkmaatschappij = this.record.project?.werkmaatschappij_id;
      this.fixPersoneelrows();
    }
    //nu is alles mooi leeg
    this.fitPersoneel(); //zorg voor juiste personeel, gegeven werkmaatschappij vh project
  }

  /**
   * Datum of tijden zijn gewijzigd. Met een debounce werken we het een en ander bij
   */
  momentChange() {
    this.debouncer.set('momentChange', () => {
      //dan moet het personeel opnieuw gesorteerd worden
      this.checkPersoneelskritiek();
      this.fitPersoneel();
    }, 500);
  }

  /**
   * Determine if this item is ready to be saved
   */
  canSave(): boolean {
    if (this.readonly || this.shouldReload) {
      return false;
    }
    if (this.form?.invalid) {
      return false;
    }
    //check specials: ngSelect
    if (!(this.record.project_id && this.record.mainFunction)) {
      return false;
    }
    //check series: at least one day
    // noinspection RedundantIfStatementJS
    if (this.planSeries.create && !Object.keys(this.planSeries.weekdays).some(day => this.planSeries.weekdays[day])) {
      return false;
    }
    return true;
  }

  roundCapacity() {
    if (this.record.capacity) {
      this.record.capacity = Math.round(this.record.capacity);
    }
  }

  /**
   * After getting lookup, or on change: make a set of relevant functions and a set of less relevant
   */
  splitFunctions() {
    this.relevantFunctions = [];
    this.irrelevantFunctions = [];
    for (let func of <Functie[]> this.lookups.functions) {
      if (this.record.hasExtrafunction(func) || this.record.mainFunction === func.id) {
        this.relevantFunctions.push(func);
      } else if (!this.readonly) {
        if (this.record.project?.heeftStandaardfunctie(func.id)) {
          this.relevantFunctions.push(func);
        } else {
          this.irrelevantFunctions.push(func);
        }
      }
    }
  }

  override afterHaalLookups() {
    this.splitFunctions();
  }

  /**
   * Something changed in the function selection
   */
  functionsChanged() {
    this.splitFunctions();
    this.fitPersoneel();
  }

  /**
   * After load data of project change: calculte the mindate
   */
  calculateMindate() {
    //we allow edit of items in the past, so mindate is the begin date of the project
    if (this.record.project) {
      this.mindate = this.record.project.begindatum;
    } else {
      this.mindate = moment().format('YYYY-MM-DD'); //fallback
    }
    this.log.debug(`New mindate: ${this.mindate}`);
  }

  /**
   * Add or remove the function from the extra functions
   * @param func
   */
  toggleExtrafunction(func: Functie) {
    if (this.readonly || this.isLeverancier) {
      return;
    }
    if (this.record.hasExtrafunction(func)) {
      this.record.planitem_function = this.record.planitem_function.filter(pif => pif.functie_id !== func.id);
    } else {
      this.record.planitem_function.push(PlanitemFunction.createNew(this.record, {
        functie_id: func.id,
        main: false
      }));
    }
    this.setDirty();
    this.functionsChanged();
  }

  /**
   * Change in personeelskeuze, kan invloed hebben op overige lijsten
   */
  personeelChange(ppi?: PersoneelPlanitem) {
    //zet de leverancier goed
    if (ppi && ppi.personeelvast_id) {
      const personeel = this.personeel(ppi.personeelvast_id);
      ppi.leverancier_id = personeel.groep === 'Personeel' ? null : (this.isLeverancier ? this.api.userdata.leverancier_id : personeel.leverancier_id);
      this.log.debug(`Set standaard leverancier`, personeel, ppi.leverancier_id);
    }
    this.fixPersoneelrows(); //bijwerken aantal zichtbare rijen
    this.fitPersoneel(); //bijwerken beschikbaar personeel
    this.checkPersoneelskritiek(); //en dit opnieuw
  }

  /**
   * ux helper bij selectie leveranciers
   */

  leveranciersInWM(): Leverancier[] {
    return (<Leverancier[]> this.lookups.leverancier || []).filter(l => l.werkmaatschappij_id === this.record.project?.werkmaatschappij_id);
  }

  /**
   * Create a text to explain what we are going to do
   */
  planSeriesText(): string {
    let standaardzin = 'Vul dit item volledig in, kies hier minstens één werkdag en een einddatum. Bij het oplaan kopiëren we dit item dan naar de aangegeven werkdagen, met dezelfde functie, project, capaciteit en begin- en eindtijd. Personeel wordt niet meegekopieerd.';
    if (this.form.invalid) {
      return standaardzin;
    }
    let datum = moment(this.record.startdate).format('D-M-Y');
    let einddatum = moment(this.planSeries.endDate).format('D-M-Y');
    let dagen = Object.keys(this.planSeries.weekdays).filter(day => this.planSeries.weekdays[day]).map(day => translated_days[day]);
    let weken = this.planSeries.everyNumWeeks > 1 ? `${this.planSeries.everyNumWeeks} weken` : 'week';
    if (!dagen.length) {
      return standaardzin;
    }
    return `We plannen deze functies en capaciteit op dit project op ${datum} van ${this.record.starttime} tot ${this.record.endtime}. Vervolgens plannen we hetzelfde op elke ${dagen.join(', ')} van elke ${weken}, totdat de begindatum van een item na ${einddatum} zou vallen. Personeel wordt niet meegekopieerd.`;
  }

  roundNumWeeks() {
    if (this.planSeries.everyNumWeeks) {
      this.planSeries.everyNumWeeks = Math.round(this.planSeries.everyNumWeeks);
    }
  }

  seriesdate(end: 'first' | 'last'): string {
    if (!this.record?.seriesItems?.length) {
      return '?';
    }
    let tag = `seriesdate_${end}`;
    if (this.record._tags[tag]) {
      return this.record._tags[tag];
    }
    let date = moment(this.record.seriesItems[end === 'first' ? 0 : this.record.seriesItems.length - 1].startdate).format('DD-MM-YYYY');
    this.record._tags[tag] = date;
    return date;
  }

  /////////////////////////////// planSeries /////////////////////////

  /**
   * Detach this item from the series (on save)
   */
  detachFromSeries() {
    this.record.resetSeries();
    this.api.toast('Dit item wordt uit de reeks verwijderd bij het opslaan');

  }

  canDeleteSeries(): boolean {
    //TODO: check if used (work order)
    return this.api.can(this.table, 'full');
  }

  /**
   * Delete the full series, except this one. Try every record, and skip the ones that can't be deleted
   */
  async deleteSeries() {
    if (!this.record?.seriesItems.length) {
      return;
    }
    let ids = this.record.seriesItems.map(pi => pi.id);
    if (this.record.id) {
      ids = ids.filter(id => id !== this.record.id);
    }
    if (!ids.length) {
      return;
    }
    if (await this.api.confirm('Verwijderen', `Hiermee verwijder je ${ids.length} items. We proberen elk item, maar het kan dat sommige niet door jou verwijderd mogen worden. Weet je zeker dat je wilt doorgaan?`)) {
      let mislukt: number = 0;
      for (let id of ids) {
        try {
          await this.api.delete('planitem', id, true, false); //no toast
        } catch (_e) {
          mislukt++;
        }
      }
      if (mislukt) {
        this.api.toast(`${mislukt} items konden niet verwijderd worden. Controleer dit item en de overige.`, 'warning');
      } else {
        this.api.toast('Alle items uit de reeks zijn vewijderd');
      }
      await this.record.checkSeries(true);
    }

  }

  ////////////////////////////// personeel //////////////////////////////
  /**
   * Make sure there are enough personeel_planitem rows for the given capacity. Extra is not a problem (here)
   * @private
   */
  fixPersoneelrows() {
    //clear all items without personeel
    this.record.personeel_planitem = this.record.personeel_planitem.filter(ppi => ppi.personeelvast_id);
    if (this.magPersoneel && (!this.readonly)) {
      //we want at least the capacity + 1 new
      while (this.record.personeel_planitem.length < this.record.capacity) {
        this.record.personeel_planitem.push(PersoneelPlanitem.createNew(this.record, {}));
      }
      //allright. Is there one empty?
      if (this.record.personeel_planitem.every(ppi => ppi.personeelvast_id)) {
        //we need one more
        this.record.personeel_planitem.push(PersoneelPlanitem.createNew(this.record, {}));
      }
    }
  }

  /**
   * Returns true if the personeel is not used in another row for personeel
   * @param personeels_id
   * @param forPPI
   */
  noDuplicatePersoneel(personeels_id: number, forPPI: PersoneelPlanitem): boolean {
    return !this.record.personeel_planitem.some(ppi => ppi !== forPPI && ppi.personeelvast_id === personeels_id);
  }

  /**
   * Tag personeel for fitness for the functions
   */
  fitPersoneel() {
    /* We maken meerdere setjes, om logisch te kunnen sorteren
    5: alles goed, en juiste leverancier
    4: best match. Has the right main function and all extras
    3: has all the functions
    2: has the main function, and some of the rest
    1: misses the right functions
    0: not active at all
     */
    const mainfunction = this.record.mainFunction;
    const extrafunctions = this.record.extraFunctions;

    //eerst maar eens personeel opnieuw setten, en dus kritiek bijwerken
    this.record.setPersoneel(<hash<Personeel>> this.lookupHashes['personeel']);

    //we recreate the fittedPersoneel list and objects with metadata
    //so the onPush change detection of ng-select works fine
    this.fittedPersoneel = [];
    for (let p of <Personeel[]> this.lookups['personeel']) {
      if ((!p.actief) || (!mainfunction)) {
        continue;
      }
      //als het personeel in een andere werkmaatschappij zit én op dit moment niet gebruikt wordt, mag het ook niet
      if (p.werkmaatschappij_id !== this.record.project.werkmaatschappij_id && (!this.record.personeel_planitem.some(ppi => ppi.personeelvast_id === p.id))) {
        continue;
      }

      if (this.isLeverancier && p.groep !== 'Extern') {
        continue;
      }

      let pdata = {
        p: p,
        functionfit: 0,
        leverancierfit: 0 //is 1 als er een leverancierfit is
      };
      this.fittedPersoneel.push(pdata);
      const hasAllExtra = extrafunctions.every(fid => p.heeftFunctie(fid));

      //check afwezigheid
      if (this.record.startdate?.length && p.afwezigOp(this.record.startdate, this.record.starttime || '00:00', this.record.endtime || '00:00')) {
        //afwezig.
        pdata.functionfit = 0;
      } else if (!this.record.project.factureerbaar) {
        pdata.functionfit = 4;
      } else if (p.heeftFunctie(mainfunction, true)) {
        //this one has the right main function
        pdata.functionfit = hasAllExtra ? 4 : 2;
      } else if (p.heeftFunctie(mainfunction)) {
        //has the right main function, but not as his basic job
        pdata.functionfit = hasAllExtra ? 3 : 2;
      } else {
        //does not have all functions, not even main
        pdata.functionfit = 1;
      }
      //als dit voor een leverancier is, juiste mensen bovenaan, met een aparte flag, zodat we in het template kunnen blijven kleuren op functionfit
      if (this.isLeverancier && p.leverancier_id === this.api.userdata.leverancier_id) {
        pdata.leverancierfit = 1;
      }
    }

    //resort
    this.fittedPersoneel.sort((a, b) =>
      b.leverancierfit - a.leverancierfit || b.functionfit - a.functionfit || a.p.naam.localeCompare(b.p.naam)
    );
    this.log.debug(`fitPersoneel`, this.fittedPersoneel);
  }

  werkmaatschappij(id: number): string {
    let wmhash = <hash<Werkmaatschappij>> this.lookupHashes['werkmaatschappij'];
    return (wmhash && wmhash[id]?.naam) || '';
  }

  personeel(id: number): Personeel {
    return <Personeel> (this.lookupHashes && this.lookupHashes['personeel'] && this.lookupHashes['personeel'][id]) || null;
  }

  protected savedata(): Datarecord {
    //filter personeel_planitem before saving
    this.record.personeel_planitem = this.record.personeel_planitem.filter(ppi => ppi.personeelvast_id);
    if (!this.record.functionalias?.length) {
      //reset to null
      this.record.functionalias = null;
    }
    let record = super.savedata();
    //leveranciers mogen niks, behalve personeel
    if (this.isLeverancier) {
      if (this.isNieuw) {
        return; //mag niet
      }
      //alles erui behalve id en ppi
      const ppiconnected = record._connected.find(c => c.table === 'personeelvast_planitem');
      record = {
        id: record.id,
        _connected: [ppiconnected]
      };
    }
    //fix personeel_planitem
    this.fixPersoneelrows();
    return record;
  }


  /////lookups

  protected async afterSave(): Promise<void> {
    //series?
    if (this.planSeries.create) {
      //we have to create a serieskey
      const chars = 'abcdefghijklmnopqrstuvwxyz';
      let serieskey: string = `${Date.now()}`;
      while (serieskey.length < 20) {
        serieskey += chars[Math.floor(Math.random() * chars.length)];
      }
      this.log.debug(`Serieskey`, serieskey);
      let data = this.savedata(); //again, we use the base data, not the model

      data['serieskey'] = serieskey;
      let save = [data];
      //clone and cleanup, so we have a cloneable version
      data = cloneDeep(data);
      delete data['id']; //next ones will be new
      //let's skip personeel
      let newconnected = [];
      for (let c of data._connected || []) {
        if (c.table === 'personeelvast_planitem') {
          continue; //skip
        }
        if (c.table === 'planitem_function') {
          //remove ids
          for (let r of c.data) {
            delete r['id'];
          }
        }
        newconnected.push(c);
      }
      data._connected = newconnected;

      let stopdate = moment(this.planSeries.endDate).startOf('day').add(1, 'day'); //must be before this date
      let date = moment(data['startdate']).startOf('day').add(1, 'day'); //not the start date


      do {
        let curday = date.isoWeekday() - 1; //0=monday
        if (this.planSeries.weekdays[daykeys[curday]]) {
          //add this date
          data = cloneDeep(data);
          data['startdate'] = date.format('YYYY-MM-DD');
          save.push(data);
        }
        //try next day
        date.add(1, 'day');
        //did we just do sunday?
        if (curday === 6 && this.planSeries.everyNumWeeks > 1) {
          //skip weeks
          date.add(this.planSeries.everyNumWeeks - 1, 'weeks');
        }
      } while (date.isBefore(stopdate));
      //save!
      this.log.debug(save);
      try {
        await this.api.save({
          table: this.table,
          data: save
        }); //doesnt give notifications because we are still in action 'save'
        this.api.toast(`Aangemaakte items: ${this.isNieuw ? save.length : save.length - 1}`); //isNieuw still works in afterSave
      } catch (_e) {
        //already logged and toasted
      }

    }

    await super.afterSave();
  }

  ///////////////////////////////////////////////////////////////////////
  /**
   * Fix the owner of the record after update of data or project change
   * @private
   */
  private fixOwner() {
    if (!this.record.project_id) {
      this.record._owner = null; //no project
    }
    if (this.record.project?.id !== this.record.project_id) {
      this.record._owner = this.projects.find(pr => pr.id === this.record.project_id);
    }
    this.calculateMindate(); //recalculate the min date for the item
    this.splitFunctions(); //set relevant functions
  }
}
