/*
 keyspipe.module.ts

Eenvoudige pipe die het resultaat van Object.keys teruggeeft.
Doordat het inputtype any is, kan je er allerlei typen en ts typedefinities zoals
hash<any> etc instoppen
De pipe vangt fouten op en geeft dan een lege array (met error in de console)
 */

import {NgModule} from '@angular/core';
import {KeysPipe} from './keyspipe';

@NgModule({
  declarations: [KeysPipe],
  exports: [KeysPipe]
}) export class KeyspipeModule {}
