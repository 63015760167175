/*
* functie_exact.ts
* 7-5-2023 - Jelmer Jellema - Spin in het Web B.V.
*
* functie_exact model. Eigenlijk alleen als sub bij functie, voor filteren op werkmaatschappij
*/

import {Basemodel, ModelField} from './basemodel';

export class FunctieExact extends Basemodel {
  static table = 'functie_exact';

  @ModelField() id: number;
  @ModelField() functie_id: number;
  @ModelField() werkmaatschappij_id: number;
  @ModelField() tijdsperiode: 'nacht'|'dag'|'wknd'|'alg';
}
