/*
* mdatepipe.module.ts
* 24-11-2021 - Jelmer Jellema - Spin in het Web B.V.
*
* Pipe die een moment of YYYY-MM-DD (HH:mm) string omzet in een string volgens een format
* {{mymoment | mdate:'DD-MM-YYYY HH:mm'}}
*/

import {NgModule} from "@angular/core";
import {Mdatepipe} from "./mdatepipe";

@NgModule({
  declarations: [Mdatepipe],
  exports: [Mdatepipe]
}) export class MdatepipeModule {}
