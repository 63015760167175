/*
* sihwunique.module.ts
* 25-8-2020 - Jelmer Jellema - Spin in het Web B.V.
*
* <form sihwunique="naamprefix" [emptyisunique]="true">...</form>
* <form [sihwunique]="['naamprefix1','anderenaamprefix']" [emptyisunique]="true">...</form>
*
* bijv
* <form sihwunique="benaming_">...</form> Valideert dat velden die beginnen met benaming_ een unieke waarde hebben. Gewoonlijk worden lege strings overgeslagen, want die worden als uniek beschouwt
* Als er een array wordt gegeven, dan wordt voor elke prefix apart gekeken of het klopt
*
* Let op: hoewel dit een form-validator is, valideert hij ook controls: control met een waarde die eerder is voorgekomen worden zelf ook invalid. Dit vanwege de ux
*/

import {NgModule} from '@angular/core';
import {LocalconfigModule} from '../../localconfig/localconfig.module';
import {SihwlogModule} from '../../sihwlog/sihwlog.module';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SihwUniqueDirective} from './sihwuniquedirective';

@NgModule({
  declarations: [
    SihwUniqueDirective
  ],
  imports: [
    LocalconfigModule,
    SihwlogModule,
    CommonModule,
    FormsModule
  ],
  exports: [SihwUniqueDirective]
}) export class SihwuniqueModule {}
