/*
* planitemlog.ts
* 5-6-2024 - Jelmer Jellema - Spin in het Web B.V.
*
* Model voor readonly log bij planitem
*/

import {Basemodel, ModelField, ModelRelation} from './basemodel';
import {Gebruiker} from './gebruiker';

export class Planitemlog extends Basemodel {
  static override table = 'planitemlog';
  static override defaultOrder = [['createdAt', 'DESC']];
  //planitem is eigenaar, dus hier niet genoemd. Json nemen we ook niet mee

  @ModelField() id: number;
  @ModelField() actie: 'nieuw' | 'wijzig' | 'verwijder' | 'terugzetten';
  @ModelField() wijzigingen_tekst: string; //met \n
  @ModelField() createdAt: string;
  @ModelRelation({model: Gebruiker, type: "1:1" }) gebruiker: Gebruiker;
}
