/*
* gebruiker_werkmaatschappij.ts
* 7-5-2023 - Jelmer Jellema - Spin in het Web B.V.
*/

import {Basemodel, ModelField} from './basemodel';

export class GebruikerWerkmaatschappij extends Basemodel {
  static table = 'gebruiker_werkmaatschappij';

  @ModelField() id: number;
  @ModelField() gebruiker_id: number;
  @ModelField() werkmaatschappij_id: number;
}
