/**
 * commentedit.ts
 * 06-03-2024 - Jelmer Jellema - Spin in het Web B.V. Apeldoorn NL
 * Component CommentEdit
 * Selector:
 * Er zijn er 2, 1 voor personeel en 1 voor projecten. Met zelfde template, dus even wat gehussel met een abstracte class
 */
import {Component, Directive, Injector, Input} from '@angular/core';
import {PersoneelDatumcomment} from '../../models/personeel_datumcomment';
import {Editmodelscherm} from '../abstracts/editmodelscherm';
import {ProjectDatumcomment} from '../../models/project_datumcomment';

///// abstract //////
@Directive()
abstract class CommentEdit<Recordclass extends (typeof PersoneelDatumcomment | typeof ProjectDatumcomment)> extends Editmodelscherm<Recordclass> {
  _starttitle = "Opmerking";
  icon = 'fas fa-comment-dots';
  fixedSize = true;
  fixedHeight = 520;
  fixedWidth = 470;

  //inputs puur voor weergaven
  @Input('naam') naam: string = "";
  @Input('datum') datum: string = "";


  /**
   * Er wordt geklikt op een kleurencombi
   * @param kleur
   */
  setKleur(kleur: [string,string]) {
    this.record.achterkleur = kleur[0];
    this.record.voorkleur = kleur[1];
  }
}

@Component({
  templateUrl: './commentedit.html',
  styleUrls: ['commentedit.scss']
})
export class PersoneelcommentEdit extends CommentEdit<typeof PersoneelDatumcomment> {

  model = PersoneelDatumcomment;

  constructor(inject: Injector) {
    super(inject, 'debug');
  }
}

@Component({
  templateUrl: './commentedit.html',
  styleUrls: ['commentedit.scss']
})
export class ProjectcommentEdit extends CommentEdit<typeof ProjectDatumcomment> {

  model = ProjectDatumcomment;

  constructor(inject: Injector) {
    super(inject, 'debug');
  }
}
