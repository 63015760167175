/**
 * exactlog.module.ts
 * 26-03-2024 - Jelmer Jellema - Spin in het Web B.V. Apeldoorn NL
 * Module voor component Exactlog
 * Selector: exactlog
 */

import {NgModule} from '@angular/core';
import {Exactloglijst} from './exactloglijst.component';
import {BasisModule} from '../../app/basis.module';
import {SihwDatatable2Module} from 'sihw-ng/datatable2/datatable2.module';

@NgModule({
  declarations: [Exactloglijst],
  imports: [
    BasisModule,
    SihwDatatable2Module
  ],
  exports: [Exactloglijst]
})
export class ExactloglijstModule {
}
Exactloglijst.register('Exactlog');
