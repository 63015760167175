/**
 * bam-import.module.ts
 * 29-04-2024 - Jelmer Jellema - Spin in het Web B.V. Apeldoorn NL
 * Module voor component BamimportScherm
 * Selector: bam-import
 */

import {NgModule} from '@angular/core';
import {BamimportScherm} from './bam-import';
import {BasisModule} from '../../app/basis.module';

@NgModule({
  declarations: [BamimportScherm],
  imports: [
    BasisModule
  ],
  exports: [BamimportScherm]
})
export class BamimportSchermModule {
}
BamimportScherm.register('Bamimportscherm');
