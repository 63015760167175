/*
* project_datumcomment.ts
* 18-3-2024 - Jelmer Jellema - Spin in het Web B.V.
*
* Comment per datum bij functietags op het planbord
*/

import {Basemodel, ModelField} from './basemodel';

export class ProjectDatumcomment extends Basemodel {
  static table = 'project_datumcomment';

  @ModelField() id: number;
  @ModelField() project_id: number;
  @ModelField() datum: string; //yyyy-mm-dd
  @ModelField() functietag: string; //de door het fe gemaakte functietag
  @ModelField() comment: string = "";
  @ModelField() achterkleur: string = "#FFFFFF";
  @ModelField() voorkleur: string = "#000000";
}
