/*
* personeel_planitem.ts
* 28-11-2022 - Jelmer Jellema - Spin in het Web B.V.
*
* This model connects personeel to planitems (n:n)
* It's is a relationField in both personeel and planitem, but relates them here only as lookup field, so this is a stop in recursion when both planitems and personel are involved
*/

import {Basemodel, ModelField, ModelRelation} from './basemodel';
import {hash} from '../app/types';
import {Personeel} from './personeel';
import {Werkbon} from './werkbon';
import {Planitem} from './planitem';
import {Leverancier} from './leverancier';
import {Project} from './project';

export class PersoneelPlanitem extends Basemodel {
  static override table = 'personeelvast_planitem';
  static override loglevel = 'debug';

  //planitem is owner
  @ModelField() id: number;
  @ModelField() personeelvast_id: number;
  @ModelField() planitem_id: number;
  @ModelField() leverancier_id: number;
  @ModelField() inkooporder: number = null;
  @ModelField() ingediend: boolean = false;

  @ModelRelation({model: Werkbon, type: '1:1'}) werkbon: Werkbon = null;

  personeel: Personeel = null;
  leverancier: Leverancier = null;

  /**
   * Vul dit item met personeel (vanuit planitem) als er een aparte lookup gedaan is
   * @param personeelLookup
   */
  setPersoneel(personeelLookup: hash<Personeel>) {
    this.personeel = personeelLookup[this.personeelvast_id];
  }

  setLeverancier(leverancierLookup: hash<Leverancier>)  {
    this.leverancier = this.leverancier_id ? leverancierLookup[this.leverancier_id] : null;
  }

  /**
   * Cast de owner naar planitem
   */
  get planitem(): Planitem {
    return <Planitem> this._owner;
  }

  get project(): Project {
    return this.planitem?.project;
  }

  /**
   * Geeft via werkbon aan of er getekend is. Werkbon moet er zijn en mag niet proforma zijn
   */
  get getekend(): boolean {
    return this.werkbon && (! this.werkbon.proforma);
  }

  /**
   * Geeft true als er een werkbon is die afwijk van de planning
   */
  get afwijkend(): boolean {
    return !! this.werkbon?.afwijkend;
  }

  get ingekocht(): boolean {
    return !! this.inkooporder;
  }

}
