/*
* dragtoscroll.ts
* 29-11-2022 - Jelmer Jellema - Spin in het Web B.V.
*/

import {Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2} from '@angular/core';
import {Sihwlog, Sihwlogger} from '../sihwlog/sihwlog';

@Directive({
  selector: '[sihw-drag-to-scroll]'
})
export class SihwDragToScroll {
  private log: Sihwlogger;

  private dragpos: any = null;

  private mouseMoveHandler: (MouseEvent) => {};
  private mouseUpHandler: (MouseEvent) => {};


  private bouncer: any = null;

  @Output('dragging-start') draggingStart = new EventEmitter<void>();
  @Output('dragging') draggingUpdate = new EventEmitter<void>();
  @Output('dragging-end') draggingEnd = new EventEmitter<void>();

  constructor(private scrollElement: ElementRef, private renderer: Renderer2, sihwlog: Sihwlog) {
    this.log = sihwlog.logger();
    this.log.debug(`sihwDraggToScroll`, scrollElement);
    //make handlers that we can remove
    this.mouseMoveHandler = this.onMousemove.bind(this);
    this.mouseUpHandler = this.onMouseUp.bind(this);
  }

  @HostListener('mousedown', ['$event']) onMousedown(e: MouseEvent) {
    //stop any bouncing
    if (this.bouncer)
    {
      clearInterval(this.bouncer);
      this.bouncer = null;
    }

    //start dragging by setting this.dragpos
    this.dragpos = {
      left: this.scrollElement.nativeElement.scrollLeft,
      top: this.scrollElement.nativeElement.scrollTop,
      start_x: e.clientX,
      start_y: e.clientY,
      planned: <{x: number, y: number}> null,
      start: Date.now(), //speed calculations
      dragging: false //set to true when scrolled over a treshhold
    };

    //we gaan scrollen:
    this.renderer.setStyle(this.scrollElement.nativeElement,'will-change','scroll-position');

    this.renderer.addClass(this.scrollElement.nativeElement, 'sihw-mousedown');
    //now start listening
    document.addEventListener('mousemove', this.mouseMoveHandler);
    document.addEventListener('mouseup', this.mouseUpHandler);
  }

  onMousemove(e: MouseEvent) {
    if (!this.dragpos) {
      return;
    }

    if (! this.dragpos.planned)
    {
      //plan een update in
      window.requestAnimationFrame(() => {
        this.doeDrag();
      });
    }
    this.dragpos.planned = {x: e.clientX, y: e.clientY};
  }

  /**
   * Uitvoeren van ingeplande drag
   */
  doeDrag()
  {
    if (this.dragpos?.planned)
    {
      //relative move
      const dy = this.dragpos.planned.y - this.dragpos.start_y;
      const dx = this.dragpos.planned.x - this.dragpos.start_x;

      this.dragpos.planned = null; //done

      this.scrollElement.nativeElement.scrollTop = this.dragpos.top - dy;
      this.scrollElement.nativeElement.scrollLeft = this.dragpos.left - dx;
      this.log.debug(`drag ${dx},${dy} --> ${this.scrollElement.nativeElement.scrollLeft}, ${this.scrollElement.nativeElement.scrollTop}`);
      if ((!this.dragpos.dragging) && (dy > 5 || dy < -5 || dx > 5 || dx < -5)) {
        //now we are dragging: set some styles and prevent the click event on dragend
        this.log.debug(`DRAGGING detected`);
        this.dragpos.dragging = true;

        //we add a .sihw-dragging class
        this.renderer.addClass(this.scrollElement.nativeElement, 'sihw-dragging');
        this.draggingStart.emit();
      }
      if (this.dragpos.dragging)
      {
        this.draggingUpdate.emit();
      }
    }
  }

  onMouseUp(e: MouseEvent) {
    if (this.dragpos) {
      //stop listening
      document.removeEventListener('mousemove', this.mouseMoveHandler);
      document.removeEventListener('mouseup', this.mouseUpHandler);
      this.renderer.removeStyle(this.scrollElement.nativeElement,'will-change');
      this.renderer.removeClass(this.scrollElement.nativeElement, 'sihw-mousedown');
      //fix click handling
      if (this.dragpos.dragging) //do NOT prevent click event when not dragged
      {
        //remove the dragging classes
        this.renderer.removeClass(this.scrollElement.nativeElement, 'sihw-dragging');

        let captureClick = function(e) {
          e.stopPropagation();
          document.removeEventListener('click', captureClick, true);
        };
        document.addEventListener('click', captureClick, true); //capture phase

        this.draggingEnd.emit();
      }
      //else handle as normal click
      this.dragpos = null; //cancel dragging
    }
  }
}
