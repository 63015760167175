/*
 Simpele pipe die de values uit een object (hash etc) teruggeeft.
 Dus het resultaat van Object.values als dat er is, of anders via Object.keys
 oordat het inputtype any is, kan je er allerlei typen en ts typedefinities zoals
hash<any> etc instoppen
De pipe vangt fouten op en geeft dan een lege array (met error in de console)
 */
import {NgModule} from '@angular/core';
import {ValuesPipe} from './valuespipe';

@NgModule({
  declarations: [ValuesPipe],
  exports: [ValuesPipe]
}) export class ValuespipeModule {}
