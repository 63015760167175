/*
* project_functie.ts
* 1-11-2022 - Jelmer Jellema - Spin in het Web B.V.
*/
import {Basemodel, ModelField} from './basemodel';

export class ProjectFunctie extends Basemodel {
  static table = 'project_functie';

  //owner: project: we don't have it as a modelfield
  @ModelField() id: number;
  @ModelField() functie_id: number;
}
