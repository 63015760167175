/**
 * verwijderdeplanitems.ts
 * 10-06-2024 - Jelmer Jellema - Spin in het Web B.V. Apeldoorn NL
 * Component VerwijderdePlanItems
 * Selector: verwijderdeplanitems
 */
import {Component, Injector, Input} from '@angular/core';
import {Appscherm} from '../abstracts/appscherm';
import {Planitem} from '../../models/planitem';
import * as moment from 'moment';

@Component({
  selector: 'verwijderdeplanitems',
  templateUrl: './verwijderdeplanitems.html',
  styleUrls: ['verwijderdeplanitems.scss']
})
export class VerwijderdePlanitems extends Appscherm {
  magTerugzetten: boolean = false;

  constructor(inject: Injector) {
    super(inject, 'debug');
    this.magTerugzetten = this.api.can('planitem', 'full');
  }

  _verwijderd: Planitem[] = [];

  @Input() set verwijderd(v: Planitem[]) {
    this._verwijderd = v;
    for (let pi of this._verwijderd) {
      const vlog = pi.planitemlog.find(pl => pl.actie === 'verwijder');
      pi._tags.verwijderdatum = moment(vlog.createdAt).format('DD-MM-YYYY HH:mm:ss');
      pi._tags.verwijderaar = vlog.gebruiker.naam;
    }
  }

  /**
   * Regel het terugzetten van het item
   * @param planitem
   */
  async terugzetten(planitem: Planitem) {
    try {
      await planitem.restore();
      this.api.toast("De dienst is teruggezet", "success");
    }
    catch {
      //wel een foutmelding geweest
    }
  }

}
