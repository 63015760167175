/*
* werkmaatschappij.ts
* 18-1-2023 - Jelmer Jellema - Spin in het Web B.V.
*/

import {Basemodel, ModelField} from './basemodel';

export class Werkmaatschappij extends Basemodel {
  static table = "werkmaatschappij";
  static defaultOrder = ['afkorting'];

  @ModelField() id: number;
  @ModelField() naam: string;
  @ModelField() afkorting: string;
  @ModelField() divisioncode: string | null = null; //nodig voor links
}
