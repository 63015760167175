/*
* planitem.ts
* 2-11-2022 - Jelmer Jellema - Spin in het Web B.V.
*
* Model for planitem: one block in the planboard
*/

import {Basemodel, ModelField, ModelRelation} from './basemodel';
import * as moment from 'moment';
import {PlanitemFunction} from './planitem_function';
import {Project} from './project';
import {Functie} from './functie';
import {PersoneelPlanitem} from './personeel_planitem';
import {Personeel} from './personeel';
import {hash} from '../app/types';
import {Leverancier} from './leverancier';
import {Planitemlog} from './planitemlog';

export class Planitem extends Basemodel {
  static table = 'planitem';
  static override loglevel = 'debug';
  static override softdeletes = true;

  //owner is project:
  @ModelField() project_id; //this one is also mapped, for edit
  @ModelField() id: number;
  @ModelField() capacity: number = 1;
  @ModelField() startdate: string = moment().format('YYYY-MM-DD');
  @ModelField() starttime: string = '07:00';
  @ModelField() endtime: string = '15:00';
  @ModelField() serieskey: string | null = null;
  @ModelField() opmerkingen: string = '';
  @ModelField() functionalias: string | null = null;
  @ModelField() aanvraagnummer: string | null = null;
  @ModelField() leverancier_id: number | null = null; //de leverancier die dit mag invullen

  //related functions
  @ModelRelation({model: PlanitemFunction}) planitem_function: PlanitemFunction[] = [];

  //personel
  @ModelRelation({model: PersoneelPlanitem}) personeel_planitem: PersoneelPlanitem[] = []; //we map op personeel_planitem, even though the backend name is personeelvast_planitem. This will be fixed because the table name is used

  @ModelRelation({model: Planitemlog}) planitemlog: Planitemlog[] = []; //de log die erbij hoort

  seriesItems: Planitem[] = null; //item in same series, after checkSeries
  functionlabel: string = null; //wordt gezet in setFunctionlabel, als nodig
  overlapcache: Map<Number, Map<Number, boolean>> = new Map(); //map per overlapminuten, en dan true / false per planitem-id. Cachet dus overlap, gegeven datplanitems niet veranderen
  personeeloverlap: PersoneelPlanitem[] = []; //zie checkPersoneeloverlap

  //caching
  private _functiontag: string | false | null = null; //not yet set

  /**
   * Return a standardized tag for the functiondescription, so we can add items to the right block
   * Returns false if there are no connected functions
   * We cache it on the fly in a private property
   */
  get functiontag(): string | false {
    if (this._functiontag !== null) {
      return this._functiontag; //cached
    }
    let mf = this.mainFunction;
    if (!mf) {
      return this._functiontag = false;
    }
    let extras = this.extraFunctions;
    extras.sort((a, b) => a - b);
    extras.unshift(mf);
    this._functiontag = extras.join('/'); //just make it an unique standardised form
    //alias?
    if (this.functionalias) {
      this._functiontag += `-${this.functionalias}`;
    }
    return this._functiontag;
  }

  get personeelids(): number[] {
    return this.personeel_planitem.map(ppi => ppi.personeelvast_id);
  }

  /**
   * Return all personeel with this item. Only valid after setPersoneel
   */
  get personeel(): Personeel[] {
    return this.personeel_planitem.map(ppi => ppi.personeel);
  }

  get startdatetime(): string {
    return `${this.startdate} ${this.starttime}`;
  }

  get enddatetime(): string {
    return `${this.endtime > this.starttime ? this.startdate : moment(this.startdate).add(1, 'day').format('YYYY-MM-DD')} ${this.endtime}`;
  }

  /**
   * Virtual property from owner: the connected project
   */
  get project(): Project | null {
    return (<Project> this._owner) ?? null;
  }

  /**
   * Is this for a combined function (main function + extras)
   */
  get isCombined(): boolean {
    return this.planitem_function.length > 1;
  }

  /**
   * Geef alle functie-ids terug
   */
  get functions(): number[] {
    if (!this.planitem_function) {
      return [];
    }
    return this.planitem_function.map(pf => pf.functie_id);
  }

  /**
   * Return the main function requested for this item, or false if it's not initialized
   */
  get mainFunction(): number | false {
    if (!this.planitem_function) {
      return false;
    }
    switch (this.planitem_function.length) {
      case 0:
        return false;
      case 1:
        return this.planitem_function[0].functie_id; //whatever
      default:
        return (this.planitem_function.find(pf => pf.main) ?? this.planitem_function[0]).functie_id;
    }
  }

  /**
   * Make sure the given function is set as main function (and when needed added)
   * the old main function will NOT be added as an extra function, but be removed
   * @param f_id
   */
  set mainFunction(f_id: number | false) {
    //cleanup the old one
    this.planitem_function = this.planitem_function.filter(f => !f.main);
    //and flag or add the new one
    for (let f of this.planitem_function) {
      if (f_id && f.functie_id === f_id) {
        f.main = true; //this one is now main
        return; //done
      }
    }
    //not found, so add it
    this.planitem_function.push(
      PlanitemFunction.createNew(this, {
        functie_id: f_id,
        main: true
      })
    );
  }

  /**
   * Return all extra functions that are not main
   */
  get extraFunctions(): number[] {
    let mainf = this.mainFunction;
    if (!mainf) {
      //never mind
      return [];
    }
    return this.functions.filter(fi => fi !== mainf);
  }

  //aantal ingevuld
  get implemented(): number {
    return this.personeel_planitem?.length ?? 0;
  }

  /**
   * Number of missing implementations
   */
  get implementationDegree(): number {
    return this.implemented - this.capacity;
  }

  /**
   * Call from planbord: make sure we know which personeel is going to work on this item. Needed for kritiek, so we call hergenereerKritiek
   * @param personeelLookup
   */
  setPersoneel(personeelLookup: hash<Personeel>) {
    //our personeel_planitem relation will take care of this
    for (let ppi of this.personeel_planitem) {
      ppi.setPersoneel(personeelLookup);
    }
    this.resetKritiek(); //make sure it is rebuild when needed
  }

  /**
   * Herbereken deel van kritiek op verzoek: bepaal of er
   * personeelsoverlap is gegeven de arbeidstijdenwet en de gegeven items
   * Haalt om efficiencyredenen niet zelf items op maar krijgt ze van
   * de aanroeper (planbord)
   * Slaat op of en waar er overlap is, en dat wordt getoond in kritiek
   * @private
   */
  checkPersoneeloverlap(checkitems: Planitem[]) {
    this.personeeloverlap = []; //weer leg
    //kan alleen als de relevante info er is
    if (this.personeel_planitem?.length && this.project && this.startdate?.length && this.starttime?.length && this.endtime?.length) {
      const pauzeminuten = 8 * 60; //8 uur pauze tussen diensten


      //We filteren er keihard doorheen, overlap gaat snel genoeg
      //we doen eerst items met overlap, daarna pas personeel
      let items = checkitems.filter(item => item.id !== this.id && this.overlap(item, pauzeminuten));
      this.personeeloverlap = this.personeel_planitem.filter(ppi => items.some(item => item.personeel_planitem.some(ppi2 => ppi2.personeelvast_id === ppi.personeelvast_id)));
      this.resetKritiek(); //kritiek moet nu opnieuw
    }
  }

  /**
   * Geeft true als dit item overlapt met het andere
   * @param pi2
   * @param pauzeMinuten als er minder dan zoveel minuten pauze is, dan geldt het als overlap (Arbeidstijdenwet)
   */
  overlap(pi2: Planitem, pauzeMinuten: number = 0): boolean {
    /**
     * Overlap is:
     * begin van pi2 ligt voor eind van deze en eind van pi2 ligt na begin van deze
     */

      //caching
    let cache: Map<Number, boolean>;
    if (this.overlapcache.has(pauzeMinuten)) {
      cache = this.overlapcache.get(pauzeMinuten);
    } else {
      cache = new Map();
      this.overlapcache.set(pauzeMinuten, cache);
    }

    if (cache.has(pi2.id)) {
      return (cache.get(pi2.id)); //true of false
    }


    let startMetPauze = pauzeMinuten ? moment(this.startdatetime).subtract(pauzeMinuten, 'minutes').format('YYYY-MM-DD HH:mm') : this.startdatetime;
    let eindMetPauze = pauzeMinuten ? moment(this.enddatetime).add(pauzeMinuten, 'minutes').format('YYYY-MM-DD HH:mm') : this.enddatetime;
    let overlappend = (pi2.startdatetime < eindMetPauze) && (pi2.enddatetime > startMetPauze);
    cache.set(pi2.id, overlappend);

    return overlappend;
  }

  /**
   * Zet de leveranciers van de personeel_planitems
   * @param leverancierLookup
   */
  setLeveranciers(leverancierLookup: hash<Leverancier>) {
    for (let ppi of this.personeel_planitem) {
      ppi.setLeverancier(leverancierLookup);
    }
    this.resetKritiek(); //make sure it is rebuild when needed
  }

  /**
   * Returns true if this item has the given function (id) as extra - not main - function
   * @param f
   */
  hasExtrafunction(f: Functie | number): boolean {
    if (!this.planitem_function) {
      return false;
    }
    let searchid = f instanceof Functie ? f.id : f;
    return this.planitem_function.some(fi => (!fi.main) && fi.functie_id === searchid);
  }

  /**
   * Maakt onze functionlabel prop voor weergave van de functies in dit item, gestandaardidseerd. Gegeven een lookup van functies volgens id => Functie
   */
  setFunctionlabel(functielookup: hash<Functie>): string {
    let mainf = this.mainFunction;
    if (mainf) {
      let names = [];

      if (this.isCombined) {
        //there are more functions
        names = this.extraFunctions.map(function_id => (functielookup[function_id]?.naam || '(onbekende functie)'));
        names.sort((a, b) => a.localeCompare(b));
      }
      names.unshift(functielookup[mainf]?.naam || '(onbekende functie)');
      this.functionlabel = names.join(' / ');
      if (this.functionalias) {
        this.functionlabel += ` (${this.functionalias})`;
      }
    } else {
      this.functionlabel = '?';
    }
    return this.functionlabel;
  }

  /**
   * Reset all caches, so they will be recalculated
   */
  resetCaches() {
    this._functiontag = null;
  }

  ///////////////////// Series //////////////////
  /**
   * Get all connected items for this series
   */
  async checkSeries(force: boolean = false) {
    if (this.seriesItems && !force) {
      return; //done
    }
    if (!this.serieskey) {
      //not a series
      this.seriesItems = [];
      return;
    }
    let where = {
      serieskey: this.serieskey
    };

    this.seriesItems = await Planitem.getAll([], [[Planitem, {
      where: where,
      order: ['startdate', 'starttime', 'endtime']
    }]]);
    this._log.debug(`seriesItems`, this.seriesItems);
    if ((!this.seriesItems.length) || (this.seriesItems.length === 1 && this.id && this.seriesItems[0].id === this.id)) {
      this._log.debug(`No connected items, removing key`);
      this.serieskey = null;
      this.seriesItems = [];
    }
  }

  /**
   * Detach this item from the series (on save)
   */
  async resetSeries() {
    this.serieskey = null;
    this.seriesItems = null;
  }

  //////////////////////////////// KRITIEK /////////////////////////////////////
  /**
   * We don't consider capacity to be filled a kritiek problem.
   * @protected
   */
  protected maakKritiek(): hash<string[]> {
    let kritiek = {
      capacity: [],
      functions: [],
      personeel: [],
      atw: [], //overlap qua arbeidstijdenwet
      afwezig: [], //afwezig personeel ingeroosterd
    };
    if (this.project.factureerbaar && this.implementationDegree > 0) {
      kritiek.capacity.push('Te veel mensen op de dienst'); //bij niet factureerbare projecten maakt het ons niks uit
    }

    //controleer op functiefit. We maken geen onderscheid tussen main en extra
    if (this.planitem_function && this.personeel_planitem && (this.project.factureerbaar)) {
      for (let ppi of this.personeel_planitem) {
        if (ppi.personeel && !(ppi.personeel.personeelvast_functie && this.planitem_function.every(
          pif => ppi.personeel.heeftFunctie(pif.functie_id)
        ))) {
          //dit personeelslid is ongekwalificeerd
          kritiek.functions.push(`${ppi.personeel?.naam || 'Een ingepland personeelslid'} is niet gekwalificeerd voor deze dienst.`);
        }
      }
    }

    //personeel uit verkeerde werkmaatschappij / heeft geen leverancier
    if (this.personeel_planitem) {
      for (let ppi of this.personeel_planitem) {
        if (ppi.personeel && ppi.personeel.werkmaatschappij_id !== this.project.werkmaatschappij_id) {
          kritiek.personeel.push(`${ppi.personeel?.naam || 'Een ingepland personeelslid'} komt uit een andere werkmaatschappij.`);
        }
        if (ppi.personeel && ppi.personeel.groep !== 'Personeel' && !ppi.leverancier_id) {
          kritiek.personeel.push(`Bij ${ppi.personeel?.naam || 'een ingepland personeelslid'} is nog geen leverancier aangegeven.`);
        }
      }
    }

    //overlappend?
    if (this.personeeloverlap?.length) {
      for (let overlappend of this.personeeloverlap) {
        kritiek.atw.push(`ATW / Overlap: ${overlappend?.personeel?.naam || 'Een personeelslid'} is al ingeroosterd.`);
      }
    }

    //afwezigheid
    if (this.startdate?.length && this.personeel_planitem) {
      for (let ppi of this.personeel_planitem) {
        if (ppi.personeel?.afwezigOp(this.startdate, this.starttime || '00:00', this.endtime || '00:00')) {
          kritiek.afwezig.push(`${ppi.personeel.naam} is op het geplande moment afwezig`);
        }
      }
    }

    return kritiek;
  }
}
