/**
 * bam-import.ts
 * 29-04-2024 - Jelmer Jellema - Spin in het Web B.V. Apeldoorn NL
 * Component BamimportScherm
 * Selector: bam-import
 */
import {Component, Injector, OnInit} from '@angular/core';
import {Appscherm} from '../abstracts/appscherm';
import {Werkmaatschappij} from '../../models/werkmaatschappij';
import {Project} from '../../models/project';
import * as moment from 'moment';
import * as Excel from 'exceljs';
import {sortBy} from 'lodash-es';

interface dienst {
  functie: string,
  alias: string,
  opmerkingen: string,
  aanvraagnummer: string,
  startdate: string,
  starttime: string,
  endtime: string
}

@Component({
  selector: 'bam-import',
  templateUrl: './bam-import.html',
  styleUrls: ['bam-import.scss']
})
export class BamimportScherm extends Appscherm implements OnInit {
  _starttitle = 'Bam import';
  icon = 'fas fa-industry';

  werkmaatschappijen: Werkmaatschappij[] = [];
  //model:
  excelfile: File;
  werkmaatschappij: Werkmaatschappij = null;
  project: Project = null;

  //import
  diensten: dienst[] = [];
  status: string = '';

  constructor(inject: Injector) {
    super(inject, 'debug');
  }

  async ngOnInit() {
    //haal wat data op
    const vandaag = moment().format('YYYY-MM-DD');
    this.werkmaatschappijen = await Werkmaatschappij.getAll([], [
      [Werkmaatschappij, {attributes: ['id', 'afkorting']}]]);
    const projecten = await Project.getAll([], [
      [Project, {
        attributes: ['id', 'naam', 'omschrijving', 'werkmaatschappij_id', 'klantnaam'],
        order: [['naam', 'asc']],
        where: {
          begindatum: {
            $or: [
              {$lte: vandaag},
              null
            ]
          },
          einddatum: {
            $or: [
              {$gte: vandaag},
              null
            ]
          }
        }
      }]
    ]);
    for (let wm of this.werkmaatschappijen) {
      wm._tags.projecten = projecten.filter(pr => {
        return pr.werkmaatschappij_id === wm.id;
      });
    }
    this.werkmaatschappij = this.werkmaatschappijen[0]; //zodat we de select kunnen verbergen als er niet meerdere zijn
  }

  /**** Fileselect ***/
  async fileGekozen(e: Event) {
    const ctrl = <HTMLInputElement> e.target;
    this.excelfile = ctrl.files[0] || null;
    this.diensten = []; //leeg
    if (this.excelfile) {
      this.status = 'Bezig met importeren...';
      let huidigerij: number;
      //we parsen hem meteen
      try {
        const workbook = new Excel.Workbook();
        await workbook.xlsx.load(await this.excelfile.arrayBuffer());
        const sheet = workbook.worksheets[workbook.worksheets.length - 1]; //als er 2 zijn, nemen we de 2e
        this.log.debug('Aantal rijen', sheet.actualRowCount);
        sheet.eachRow((row, rownumber) => {
          huidigerij = rownumber;
          if (rownumber === 1) {
            return; //headers
          }
          const values = <Excel.CellValue[]> row.values;
          //TEST: rijnummer eraf
          //  values.shift();
          let startdate: moment.Moment;
          try {
            startdate = moment((<Date> values[3]).toISOString());
          } catch {
            throw {fout: 'Ongeldige datum', values: values};
          }
          if (!startdate?.isValid()) {
            throw {fout: 'Ongeldige datum', values: values};
          }
          const dienst: dienst = {
            startdate: startdate.format('YYYY-MM-DD'),
            starttime: this.exceltijd(<number> values[4]),
            endtime: this.exceltijd(<number> values[5]),
            functie: `NNB`,
            alias: `(BAM) ${(<string> (values[7] || '')).trim()} - ${(<string> (values[9] || '')).trim()}`,
            aanvraagnummer: <string> (values[8] || '').toString(10),
            opmerkingen: (<string> values[10]).trim()
          };
          if (values[11] && (<string> values[11]).length) {
            dienst.opmerkingen += " CP: " + (<string> values[11]).trim();
          }
          if (values[11] && (<string> values[12]).length) {
            dienst.opmerkingen += " " + (<string> values[12]).trim();
          }
          this.diensten.push(dienst);
        });
      } catch (e: any) {
        this.log.warn(e);
        this.status = `Fout in rij ${huidigerij}: ${e.fout ?? 'parsefout'}`;
        this.diensten = []; //weg
        return;
      }
    } else {
      this.status = 'Geen bestand gevonden';
    }
    this.diensten = sortBy(this.diensten, ['startdate', 'starttime']);
    this.log.debug(this.diensten);
    this.status = `${this.diensten.length} diensten klaar voor import`;
    return true;
  }

  /**
   * Helper ipv form.invalid ivm checken excel
   */
  isValid(): boolean {
    return this.project && (!!this.diensten?.length);
  }

  /**
   * Uitvoeren: dat doet het backend, hebben we besloten
   */
  async doeImport() {
    if (!(this.diensten?.length && await this.api.confirm('Importeren diensten', `Er worden ${this.diensten.length} diensten geïmporteerd op de aangegeven functies en functiealiassen bij project ${this.project.naam}. We controleren niet of deze diensten al bestaan. Weet je heel zeker dat je door wilt gaan?`))) {
      return; //we doen het niet
    }
    this.status = 'Opslaan functies';
    //we sturen het naar achteren
    try {
      await this.api.bamImport(this.project, this.diensten);
    } catch (e) {
      this.status = `Fout bij importeren: ${e.message || e.toString()}`;
      return;
    }
    this.api.toast('De diensten zijn geïmporteerd', 'success');
    this.closeWindow();
  }

  /**
   * Helper bij parsen, een getal naar een string
   * @param intijd
   * @private
   * @throws
   */
  private exceltijd(intijd: number): string {
    //we maken het een string en doen een regex, want met rekenen met 7,3 wordt het al snel 7,29999
    let tijdstr = intijd.toString(10);
    this.log.debug(`intijd`, intijd, tijdstr);
    let m = /^(\d+)(\.(\d+))?$/.exec(tijdstr);
    if (!m) {
      this.log.debug(`Kon niet matchen`);
      throw {fout: 'Geen exceltijd'};
    }
    this.log.debug(m);
    return `${m[1].length < 2 ? '0' : ''}${m[1]}:${m[3] || '0'}${((!m[3]) || m[3]?.length < 2) ? '0' : ''}`;
  }
}
