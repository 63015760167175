/*
* leveranciers.module.ts
* 9-3-2023 - Jelmer Jellema - Spin in het Web B.V.
*/

import {Leverancierlijstscherm} from './leverancierlijst';
import {NgModule} from '@angular/core';
import {BasisModule} from '../../app/basis.module';
import {Leverancierportaal} from './leverancierportaal';

const schermen = [
  Leverancierlijstscherm,
  Leverancierportaal
];

@NgModule({
  declarations: schermen,
  imports: [
    BasisModule
  ],
  providers: [],
  exports: schermen
}) export class LeveranciersModule{}

Leverancierlijstscherm.register('Leverancierlijstscherm');
Leverancierportaal.register("Leverancierportaal");
