/**
 * exactlog.ts
 * 26-03-2024 - Jelmer Jellema - Spin in het Web B.V. Apeldoorn NL
 * Component Exactlog
 * Selector: exactlog
 */
import {Component, Injector} from '@angular/core';
import {Datatable2scherm} from '../abstracts/datatable2scherm.class';
import {DatatableDef} from 'sihw-ng/datatable2/datatable2.component';
import {Exactlog} from '../../models/exactlog';
import {Gebruiker} from '../../models/gebruiker';
import {Werkmaatschappij} from '../../models/werkmaatschappij';
import {Basemodel} from '../../models/basemodel';
import {hash} from '../../app/types';

@Component({
  selector: 'exactlog',
  templateUrl: './exactloglijst.component.html',
  styleUrls: ['exactloglijst.component.scss']
})
export class Exactloglijst extends Datatable2scherm {
  _starttitle = 'Exactlog';
  icon = 'fas fa-cash-register';
  model = Exactlog;
  tableQuery = Exactlog.recordquery([Werkmaatschappij, Gebruiker], [
    [Exactlog, {where: {niveau: {$not: 'debug'}}, order: [['createdAt','desc']], limit: 3000}],
    [Werkmaatschappij, {attributes: ['afkorting']}],
    [Gebruiker, {attributes: ['naam']}]
  ]);
  subscribeDatachanges = false; //gewoon niet
  debug: boolean = false;
  animateNewdata: boolean = false; //voor class op reload-button

  constructor(inject: Injector) {
    super(inject, 'debug');
  }


  /**
   * Reload bij optiewijziging (debug-modus bijvoorbeeld)
   */
  async reloaddata() {
    const logopties: hash<any> = {};
    if (!this.debug) {
      //dan filteren op debug
      logopties.where = {niveau: {$not: 'debug'}};
      logopties.order = [['createdAt', 'desc']];
      logopties.limit = 3000; //om het snel te houden
    }
    const options: [typeof Basemodel, hash<any>][] = [
      [Exactlog, logopties],
      [Werkmaatschappij, {attributes: ['afkorting']}],
      [Gebruiker, {attributes: ['naam']}]
    ]; //de standaard opties

    this.tableQuery = Exactlog.recordquery([Werkmaatschappij, Gebruiker], options);
    await this.planHaalData();
  }

  protected initDatawijzigsubscription() {
    this.subscription(this.api.onDatawijziging([Exactlog]).subscribe(
      () => {
        this.animateNewdata = true; //wordt false onanimationend
      }
    ));
  }

  tableDefinition: DatatableDef = {
    track: 'id',
    standaardsortering: {
      key: 'id',
      richting: -1
    },
    rijclasses: (el: Exactlog) => {
      return [`exactlog_${el.niveau}`];
    },
    velden: {
      id: {
        label: 'Nr',
        type: 'int'
      },
      onderwerp: {
        label: 'Onderwerp',
        type: 'enum'
      },
      niveau: {
        label: 'Niveau',
        type: 'enum'
      },
      melding: {
        label: 'Melding'
      },
      werkmaatschappij: {
        label: 'WM',
        type: 'enum',
        prop: 'werkmaatschappij.afkorting',
        format: wm => wm || '-'
      },
      gebruiker: {
        label: 'Gebruiker',
        type: 'enum',
        prop: 'gebruiker.naam'
      },
      createdAt: {
        label: 'Tijdstip',
        type: ['moment', {format: 'DD-MM-YY HH:mm:ss'}]
      },
      request: {
        label: 'Request',
        style: () => {
          return {'text-wrap': 'wrap', 'width': '50px'};
        }
      },
    }
  };

}
