/*
* personeel_datumcomment.ts
* 6-3-2024 - Jelmer Jellema - Spin in het Web B.V.
*
* Comment per datum bij personeel op het planbord
*/

import {Basemodel, ModelField} from './basemodel';

export class PersoneelDatumcomment extends Basemodel {
  static table = 'personeelvast_datumcomment';

  @ModelField() id: number;
  @ModelField() personeelvast_id: number;
  @ModelField() datum: string; //yyyy-mm-dd
  @ModelField() comment: string = "";
  @ModelField() achterkleur: string = "#FFFFFF";
  @ModelField() voorkleur: string = "#000000";
}
