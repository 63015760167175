/*
* gebruiker.ts
* 7-5-2023 - Jelmer Jellema - Spin in het Web B.V.
*
* Simpel model om dit bruikbaar te krijgen in het gebruikersscherm
*/

import {Basemodel, ModelField, ModelRelation} from './basemodel';
import {GebruikerWerkmaatschappij} from './gebruiker_werkmaatschappij';

export class Gebruiker extends Basemodel {
  static table= 'gebruiker';
  static defaultOrder = ['gebruikersnaam'];

  @ModelField() id: number;
  @ModelField() gebruikersnaam: string = '';
  @ModelField() naam: string = '';
  @ModelField() wachtwoord: string = null; //wordt nooit teruggegeven van achter, maar kan wel gesaved worden
  @ModelField() actief: boolean = true;
  @ModelField() app_admin: boolean = false;
  @ModelField() exact_admin: boolean = false;
  @ModelField() groep_id: number;
  @ModelField() leverancier_id: number = null; //relatie met leverancier: gebruiker mag namens deze leverancier plannen (moet wel juiste groep hebben, maar dat regelen backend en ux)

  @ModelRelation({model: GebruikerWerkmaatschappij}) gebruiker_werkmaatschappij: GebruikerWerkmaatschappij[] = [];
}
