/*
* openfocus.module.ts
* 2-12-2020 - Jelmer Jellema - Spin in het Web B.V.
*
* <input autofocus ....> of <input sihwAutofocus>
* autofocus binnen OPA's: zorgt dat na openen van de input deze probeert de focus te krijgen. Implementeert dus feitelijk de specs van het autofocusattribute, maar dan in multiwindow/view-omgeving
* Gebruik sihwAutofocus om te zorgen dat de browser niet klaagt in de console over andere elementen met focus
*/

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Autofocusdirective} from './autofocusdirective';

@NgModule({
  declarations: [
    Autofocusdirective
  ],
  imports: [
    CommonModule
  ],
  exports: [Autofocusdirective]
}) export class SiwhAutofocusModule {}
