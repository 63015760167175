/*
* leverancier.ts
* 9-3-2023 - Jelmer Jellema - Spin in het Web B.V.
*
* Leverancier uit exact
*/

import {Basemodel, ModelField, ModelRelation} from './basemodel';
import {hash} from '../app/types';
import {LeverancierDocument} from './leverancier_document';

export class Leverancier extends Basemodel {
  static table = 'leverancier';
  static defaultOrder = ['naam'];
  @ModelField() id: number;
  @ModelField() naam: string;
  @ModelField() werkmaatschappij_id: number;
  @ModelField() leverancierstype: 'Inhuur' | 'ZZP Poule';
  @ModelField() exact_id: string = null;
  @ModelField() wijzigingen: string = null; //json van wijzigingen
  @ModelField() portaal_afkeurreden: string = null; //opgegeven reden van afkeuren
  @ModelField() goedkeurstatus: 'goedgekeurd'|'forceer'|'afgekeurd' = 'goedgekeurd'; //wat vindt kantoor ervan?
  @ModelField() beoordelen: boolean = false; //moet kantoor dit beoordelen?

  @ModelRelation({model: LeverancierDocument, type: '1:n'}) leverancier_document: LeverancierDocument[];

  public get bron(): 'Exact' | 'ATRPlan' {
    return this.exact_id ? 'Exact' : 'ATRPlan';
  }

  /**
   * Levert een hash op uit de wijzigingen
   */
  public get wijzigingendata(): hash<any> {
    if (!this.wijzigingen) {
      return {};
    }
    try {
      return JSON.parse(this.wijzigingen);
    } catch {
      //mislukt, reset de boel
      this.wijzigingen = null;
      return {};
    }
  }

  /**
   * Set de hash met wijzigingen als json
   * @param data
   */
  public set wijzigingendata(data: hash<any> | null) {
    //check even op lege strings
    const nieuwwijzigingen = {};
    for (let k of Object.keys(data || {})) {
      if (typeof data[k] === 'string') {
        if (data[k].length === 0) {
          continue; //skip
        }
      }
      nieuwwijzigingen[k] = data[k]; //en anders overnemen
    }
    //is er iets op te slaan?
    this.wijzigingen = null; //uitgangspunt
    if (Object.keys(nieuwwijzigingen).length > 0) {
      try {
        this.wijzigingen = JSON.stringify(nieuwwijzigingen);
      } catch (e) {
        this._log.error(e);
      }
    }
  }

  public get bevatWijzigingen(): boolean {
    return !! this.wijzigingen && Object.keys(this.wijzigingendata).length > 0;
  }

  protected maakKritiek(): hash<string[]> {
    const kritiek = super.maakKritiek();

    //documenten?
    kritiek['documenten'] ||= [];
    for(let doc of this.leverancier_document || []) {
      if (doc.verplicht && ! doc.heeftDocument)
      {
        kritiek['documenten'].push(`Het document ${doc.omschrijving} is verplicht`);
      }
    }
    return kritiek;
  }
}
