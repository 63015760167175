/**
 Een verzameling simpele pipes.Zie de directories
 */

import {KeyspipeModule} from './keys/keyspipe.module';
import {NgModule} from '@angular/core';
import {SortbypipeModule} from './sortby/sortbypipe.module';
import {ValuespipeModule} from './values/valuespipe.module';
import {MdatepipeModule} from "./mdate/mdatepipe.module";

@NgModule({
  exports: [
    KeyspipeModule,
    ValuespipeModule,
    SortbypipeModule,
    MdatepipeModule
  ]
}) export class SihwpipesModule {}
