/*
* werkbonnen.module.ts
* 1-3-2023 - Jelmer Jellema - Spin in het Web B.V.
*/

import {NgModule} from '@angular/core';
import {BasisModule} from '../../app/basis.module';
import {Werkbonnenlijstscherm} from './werkbonnenlijst';
import {SihwDatatable2Module} from 'sihw-ng/datatable2/datatable2.module';
import {Werkbonedit} from './werkbonedit';
import {SihwtijdveldModule} from 'sihw-ng/controls/sihwtijdveld/sihwtijdveld.module';
import {SihwdatumveldModule} from 'sihw-ng/controls/sihwdatumveld/sihwdatumveld.module';

const schermen = [
  Werkbonnenlijstscherm,
  Werkbonedit
];

@NgModule({
  declarations: schermen,
  imports: [
    BasisModule,
    SihwDatatable2Module,
    SihwtijdveldModule,
    SihwdatumveldModule
  ],
  exports: schermen
})
export class WerkbonnenModule {
}

Werkbonnenlijstscherm.register('Werkbonnenlijstscherm');
Werkbonedit.register('Werkbonedit');
