/**
 * totaalexport.module.ts
 * 20-05-2024 - Jelmer Jellema - Spin in het Web B.V. Apeldoorn NL
 * Module voor component Totaalexportscherm
 * Selector: totaalexport
 */

import {NgModule} from '@angular/core';
import {Totaalexportscherm} from './totaalexport';
import {FileSaverModule} from 'ngx-filesaver';

@NgModule({
  declarations: [Totaalexportscherm],
  imports: [FileSaverModule],
  exports: [Totaalexportscherm]
})
export class TotaalexportschermModule {
}
Totaalexportscherm.register('Totaalexportscherm');
