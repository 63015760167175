<ng-container *ngFor="let pi of _verwijderd">
  <ng-container *ngIf="pi.isDeleted"> <comment>Als er een restore is geweest verdwijnt hij uit de lijst</comment>
    <div class="planitem">
      <div class="planitemheader">
        <div>{{ pi.project.naam }} {{ pi.project.omschrijving }}</div>
        <div>{{ pi.startdate | mdate:"DD-MM-YYYY" }} {{ pi.starttime }}</div>
      </div>
      <div class="planitembody">
        <table class="table table-sm table-sm table-hover">
          <tr>
            <th>Project</th>
            <td>{{pi.project.naam}} {{pi.project.omschrijving}}</td>
          </tr>
          <tr>
            <th>Start</th>
            <td>{{ pi.startdate | mdate:"DD-MM-YYYY" }} {{ pi.starttime }}</td>
          </tr>
          <tr>
            <th>Functie</th>
            <td>{{ pi.functionlabel }}</td>
          </tr>
          <tr>
            <th>Capaciteit</th>
            <td>{{ pi.capacity }}</td>
          </tr>
          <tr>
            <th>Verwijderd</th>
            <td>{{ pi._tags.verwijderdatum }}</td>
          </tr>
          <tr>
            <th>Verwijderd door</th>
            <td>{{ pi._tags.verwijderaar }}</td>
          </tr>
        </table>
        <div class="planitemfooter">
          <button class="btn btn-primary" *ngIf="magTerugzetten" (click)="terugzetten(pi)">Terugzetten</button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
